//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


KeywordType = {
  'WORD' : 1,
  'AUTHOR' : 2,
  'ORG' : 3,
  'ASSIGNEE' : 4,
  'FOS' : 5,
  'ALL' : 100
};
SearchOrganizationReq = function(args) {
  this.organization = null;
  this.keyword = null;
  this.size = null;
  this.value = null;
  if (args) {
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = args.organization;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
  }
};
SearchOrganizationReq.prototype = {};
SearchOrganizationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organization = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.size = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.value = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchOrganizationReq.prototype.write = function(output) {
  output.writeStructBegin('SearchOrganizationReq');
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRING, 1);
    output.writeString(this.organization);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 2);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I32, 3);
    output.writeI32(this.size);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.I32, 4);
    output.writeI32(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

OrganizationKeyword = function(args) {
  this.keyword = null;
  this.freq = null;
  this.freqGlobal = null;
  this.corr = null;
  if (args) {
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.freq !== undefined && args.freq !== null) {
      this.freq = args.freq;
    }
    if (args.freqGlobal !== undefined && args.freqGlobal !== null) {
      this.freqGlobal = args.freqGlobal;
    }
    if (args.corr !== undefined && args.corr !== null) {
      this.corr = args.corr;
    }
  }
};
OrganizationKeyword.prototype = {};
OrganizationKeyword.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.freq = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.freqGlobal = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.corr = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

OrganizationKeyword.prototype.write = function(output) {
  output.writeStructBegin('OrganizationKeyword');
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 1);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.freq !== null && this.freq !== undefined) {
    output.writeFieldBegin('freq', Thrift.Type.I32, 2);
    output.writeI32(this.freq);
    output.writeFieldEnd();
  }
  if (this.freqGlobal !== null && this.freqGlobal !== undefined) {
    output.writeFieldBegin('freqGlobal', Thrift.Type.I32, 3);
    output.writeI32(this.freqGlobal);
    output.writeFieldEnd();
  }
  if (this.corr !== null && this.corr !== undefined) {
    output.writeFieldBegin('corr', Thrift.Type.I32, 4);
    output.writeI32(this.corr);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchOrganizationResp = function(args) {
  this.keywords = null;
  if (args) {
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [OrganizationKeyword]);
    }
  }
};
SearchOrganizationResp.prototype = {};
SearchOrganizationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.keywords = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new OrganizationKeyword();
          elem6.read(input);
          this.keywords.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchOrganizationResp.prototype.write = function(output) {
  output.writeStructBegin('SearchOrganizationResp');
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.keywords.length);
    for (var iter7 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter7))
      {
        iter7 = this.keywords[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabularyReq = function(args) {
  this.organization = null;
  this.keyword = null;
  this.top = null;
  if (args) {
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = args.organization;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.top !== undefined && args.top !== null) {
      this.top = args.top;
    }
  }
};
SearchVocabularyReq.prototype = {};
SearchVocabularyReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organization = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.top = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabularyReq.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyReq');
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRING, 1);
    output.writeString(this.organization);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 2);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.top !== null && this.top !== undefined) {
    output.writeFieldBegin('top', Thrift.Type.I32, 3);
    output.writeI32(this.top);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

VocabularyKeyword = function(args) {
  this.id = null;
  this.keyword = null;
  this.freq = null;
  this.files = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.freq !== undefined && args.freq !== null) {
      this.freq = args.freq;
    }
    if (args.files !== undefined && args.files !== null) {
      this.files = args.files;
    }
  }
};
VocabularyKeyword.prototype = {};
VocabularyKeyword.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.id = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.freq = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.files = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VocabularyKeyword.prototype.write = function(output) {
  output.writeStructBegin('VocabularyKeyword');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.I32, 1);
    output.writeI32(this.id);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 2);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.freq !== null && this.freq !== undefined) {
    output.writeFieldBegin('freq', Thrift.Type.I32, 3);
    output.writeI32(this.freq);
    output.writeFieldEnd();
  }
  if (this.files !== null && this.files !== undefined) {
    output.writeFieldBegin('files', Thrift.Type.I32, 4);
    output.writeI32(this.files);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabularyResp = function(args) {
  this.keywords = null;
  if (args) {
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [VocabularyKeyword]);
    }
  }
};
SearchVocabularyResp.prototype = {};
SearchVocabularyResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.keywords = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new VocabularyKeyword();
          elem14.read(input);
          this.keywords.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabularyResp.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyResp');
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.keywords.length);
    for (var iter15 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter15))
      {
        iter15 = this.keywords[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabularyHistoryReq = function(args) {
  this.organization = null;
  this.keywords = null;
  this.fromYear = null;
  if (args) {
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = args.organization;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.fromYear !== undefined && args.fromYear !== null) {
      this.fromYear = args.fromYear;
    }
  }
};
SearchVocabularyHistoryReq.prototype = {};
SearchVocabularyHistoryReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organization = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.keywords = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = input.readString().value;
          this.keywords.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I16) {
        this.fromYear = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabularyHistoryReq.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyHistoryReq');
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRING, 1);
    output.writeString(this.organization);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter23 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter23))
      {
        iter23 = this.keywords[iter23];
        output.writeString(iter23);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.fromYear !== null && this.fromYear !== undefined) {
    output.writeFieldBegin('fromYear', Thrift.Type.I16, 3);
    output.writeI16(this.fromYear);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

VocabularyHistoryData = function(args) {
  this.year = null;
  this.freq = null;
  this.files = null;
  if (args) {
    if (args.year !== undefined && args.year !== null) {
      this.year = args.year;
    }
    if (args.freq !== undefined && args.freq !== null) {
      this.freq = args.freq;
    }
    if (args.files !== undefined && args.files !== null) {
      this.files = args.files;
    }
  }
};
VocabularyHistoryData.prototype = {};
VocabularyHistoryData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.year = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.freq = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.files = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VocabularyHistoryData.prototype.write = function(output) {
  output.writeStructBegin('VocabularyHistoryData');
  if (this.year !== null && this.year !== undefined) {
    output.writeFieldBegin('year', Thrift.Type.STRING, 1);
    output.writeString(this.year);
    output.writeFieldEnd();
  }
  if (this.freq !== null && this.freq !== undefined) {
    output.writeFieldBegin('freq', Thrift.Type.I32, 2);
    output.writeI32(this.freq);
    output.writeFieldEnd();
  }
  if (this.files !== null && this.files !== undefined) {
    output.writeFieldBegin('files', Thrift.Type.I32, 3);
    output.writeI32(this.files);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

VocabularyHistoryKeyword = function(args) {
  this.keyword = null;
  this.data = null;
  if (args) {
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = Thrift.copyList(args.data, [VocabularyHistoryData]);
    }
  }
};
VocabularyHistoryKeyword.prototype = {};
VocabularyHistoryKeyword.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.data = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new VocabularyHistoryData();
          elem30.read(input);
          this.data.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VocabularyHistoryKeyword.prototype.write = function(output) {
  output.writeStructBegin('VocabularyHistoryKeyword');
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 1);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.data.length);
    for (var iter31 in this.data)
    {
      if (this.data.hasOwnProperty(iter31))
      {
        iter31 = this.data[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabularyHistoryResp = function(args) {
  this.keywords = null;
  this.totals = null;
  if (args) {
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [VocabularyHistoryKeyword]);
    }
    if (args.totals !== undefined && args.totals !== null) {
      this.totals = Thrift.copyList(args.totals, [VocabularyHistoryData]);
    }
  }
};
SearchVocabularyHistoryResp.prototype = {};
SearchVocabularyHistoryResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.keywords = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new VocabularyHistoryKeyword();
          elem38.read(input);
          this.keywords.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size39 = 0;
        var _rtmp343;
        this.totals = [];
        var _etype42 = 0;
        _rtmp343 = input.readListBegin();
        _etype42 = _rtmp343.etype;
        _size39 = _rtmp343.size;
        for (var _i44 = 0; _i44 < _size39; ++_i44)
        {
          var elem45 = null;
          elem45 = new VocabularyHistoryData();
          elem45.read(input);
          this.totals.push(elem45);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabularyHistoryResp.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyHistoryResp');
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.keywords.length);
    for (var iter46 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter46))
      {
        iter46 = this.keywords[iter46];
        iter46.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.totals !== null && this.totals !== undefined) {
    output.writeFieldBegin('totals', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.totals.length);
    for (var iter47 in this.totals)
    {
      if (this.totals.hasOwnProperty(iter47))
      {
        iter47 = this.totals[iter47];
        iter47.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabulary2Req = function(args) {
  this.organization = null;
  this.keyword = null;
  this.type = null;
  this.top = null;
  if (args) {
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = args.organization;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.top !== undefined && args.top !== null) {
      this.top = args.top;
    }
  }
};
SearchVocabulary2Req.prototype = {};
SearchVocabulary2Req.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organization = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.top = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabulary2Req.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabulary2Req');
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRING, 1);
    output.writeString(this.organization);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 2);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 3);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.top !== null && this.top !== undefined) {
    output.writeFieldBegin('top', Thrift.Type.I32, 4);
    output.writeI32(this.top);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

VocabularyKeyword2 = function(args) {
  this.name = null;
  this.type = null;
  this.freq = null;
  this.files = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.freq !== undefined && args.freq !== null) {
      this.freq = args.freq;
    }
    if (args.files !== undefined && args.files !== null) {
      this.files = args.files;
    }
  }
};
VocabularyKeyword2.prototype = {};
VocabularyKeyword2.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.freq = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.files = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VocabularyKeyword2.prototype.write = function(output) {
  output.writeStructBegin('VocabularyKeyword2');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.freq !== null && this.freq !== undefined) {
    output.writeFieldBegin('freq', Thrift.Type.I32, 3);
    output.writeI32(this.freq);
    output.writeFieldEnd();
  }
  if (this.files !== null && this.files !== undefined) {
    output.writeFieldBegin('files', Thrift.Type.I32, 4);
    output.writeI32(this.files);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchVocabulary2Resp = function(args) {
  this.keywords = null;
  if (args) {
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [VocabularyKeyword2]);
    }
  }
};
SearchVocabulary2Resp.prototype = {};
SearchVocabulary2Resp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.keywords = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new VocabularyKeyword2();
          elem54.read(input);
          this.keywords.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchVocabulary2Resp.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabulary2Resp');
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.keywords.length);
    for (var iter55 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter55))
      {
        iter55 = this.keywords[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

