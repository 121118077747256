//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


SearchDataType = {
  'KEYWORD' : 1,
  'AUTHOR' : 2,
  'ORG' : 3,
  'ASSIGNEE' : 4,
  'FOS' : 5,
  'CHANNEL' : 6,
  'TWEET_TEXT' : 7,
  'SOURCE_ID' : 8
};
SearchOperation = {
  'MUST' : 1,
  'SHOULD' : 2,
  'MUST_NOT' : 3
};
AggregationType = {
  'TWITTER_USER_ID' : 1,
  'TWITTER_TAGS' : 2,
  'FOS' : 3,
  'KEYWORDS' : 4,
  'YEAR' : 5,
  'PUBLISHER' : 6
};
Author = function(args) {
  this.affiliation = null;
  this.family = null;
  this.given = null;
  if (args) {
    if (args.affiliation !== undefined && args.affiliation !== null) {
      this.affiliation = args.affiliation;
    }
    if (args.family !== undefined && args.family !== null) {
      this.family = args.family;
    }
    if (args.given !== undefined && args.given !== null) {
      this.given = args.given;
    }
  }
};
Author.prototype = {};
Author.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.affiliation = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.family = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.given = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Author.prototype.write = function(output) {
  output.writeStructBegin('Author');
  if (this.affiliation !== null && this.affiliation !== undefined) {
    output.writeFieldBegin('affiliation', Thrift.Type.STRING, 1);
    output.writeString(this.affiliation);
    output.writeFieldEnd();
  }
  if (this.family !== null && this.family !== undefined) {
    output.writeFieldBegin('family', Thrift.Type.STRING, 2);
    output.writeString(this.family);
    output.writeFieldEnd();
  }
  if (this.given !== null && this.given !== undefined) {
    output.writeFieldBegin('given', Thrift.Type.STRING, 3);
    output.writeString(this.given);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Document = function(args) {
  this.type = null;
  this.author = null;
  this.title = null;
  this.summary = null;
  this.year = null;
  this.date = null;
  this.url = null;
  this.id = null;
  this.issn = null;
  this.assignee = null;
  this.keywords = null;
  this.classification = null;
  this.journal = null;
  this.geohash = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.author !== undefined && args.author !== null) {
      this.author = Thrift.copyList(args.author, [Author]);
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.summary !== undefined && args.summary !== null) {
      this.summary = args.summary;
    }
    if (args.year !== undefined && args.year !== null) {
      this.year = args.year;
    }
    if (args.date !== undefined && args.date !== null) {
      this.date = args.date;
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.issn !== undefined && args.issn !== null) {
      this.issn = Thrift.copyList(args.issn, [null]);
    }
    if (args.assignee !== undefined && args.assignee !== null) {
      this.assignee = Thrift.copyList(args.assignee, [null]);
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.classification !== undefined && args.classification !== null) {
      this.classification = Thrift.copyList(args.classification, [null]);
    }
    if (args.journal !== undefined && args.journal !== null) {
      this.journal = args.journal;
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = Thrift.copyList(args.geohash, [null]);
    }
  }
};
Document.prototype = {};
Document.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.author = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new Author();
          elem6.read(input);
          this.author.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.summary = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.year = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.date = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.issn = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = input.readString().value;
          this.issn.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size14 = 0;
        var _rtmp318;
        this.assignee = [];
        var _etype17 = 0;
        _rtmp318 = input.readListBegin();
        _etype17 = _rtmp318.etype;
        _size14 = _rtmp318.size;
        for (var _i19 = 0; _i19 < _size14; ++_i19)
        {
          var elem20 = null;
          elem20 = input.readString().value;
          this.assignee.push(elem20);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.LIST) {
        var _size21 = 0;
        var _rtmp325;
        this.keywords = [];
        var _etype24 = 0;
        _rtmp325 = input.readListBegin();
        _etype24 = _rtmp325.etype;
        _size21 = _rtmp325.size;
        for (var _i26 = 0; _i26 < _size21; ++_i26)
        {
          var elem27 = null;
          elem27 = input.readString().value;
          this.keywords.push(elem27);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.LIST) {
        var _size28 = 0;
        var _rtmp332;
        this.classification = [];
        var _etype31 = 0;
        _rtmp332 = input.readListBegin();
        _etype31 = _rtmp332.etype;
        _size28 = _rtmp332.size;
        for (var _i33 = 0; _i33 < _size28; ++_i33)
        {
          var elem34 = null;
          elem34 = input.readString().value;
          this.classification.push(elem34);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.journal = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.LIST) {
        var _size35 = 0;
        var _rtmp339;
        this.geohash = [];
        var _etype38 = 0;
        _rtmp339 = input.readListBegin();
        _etype38 = _rtmp339.etype;
        _size35 = _rtmp339.size;
        for (var _i40 = 0; _i40 < _size35; ++_i40)
        {
          var elem41 = null;
          elem41 = input.readString().value;
          this.geohash.push(elem41);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Document.prototype.write = function(output) {
  output.writeStructBegin('Document');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.author !== null && this.author !== undefined) {
    output.writeFieldBegin('author', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.author.length);
    for (var iter42 in this.author)
    {
      if (this.author.hasOwnProperty(iter42))
      {
        iter42 = this.author[iter42];
        iter42.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 3);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.summary !== null && this.summary !== undefined) {
    output.writeFieldBegin('summary', Thrift.Type.STRING, 4);
    output.writeString(this.summary);
    output.writeFieldEnd();
  }
  if (this.year !== null && this.year !== undefined) {
    output.writeFieldBegin('year', Thrift.Type.STRING, 5);
    output.writeString(this.year);
    output.writeFieldEnd();
  }
  if (this.date !== null && this.date !== undefined) {
    output.writeFieldBegin('date', Thrift.Type.STRING, 6);
    output.writeString(this.date);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 7);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 8);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.issn !== null && this.issn !== undefined) {
    output.writeFieldBegin('issn', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRING, this.issn.length);
    for (var iter43 in this.issn)
    {
      if (this.issn.hasOwnProperty(iter43))
      {
        iter43 = this.issn[iter43];
        output.writeString(iter43);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.assignee !== null && this.assignee !== undefined) {
    output.writeFieldBegin('assignee', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.STRING, this.assignee.length);
    for (var iter44 in this.assignee)
    {
      if (this.assignee.hasOwnProperty(iter44))
      {
        iter44 = this.assignee[iter44];
        output.writeString(iter44);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 11);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter45 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter45))
      {
        iter45 = this.keywords[iter45];
        output.writeString(iter45);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.classification !== null && this.classification !== undefined) {
    output.writeFieldBegin('classification', Thrift.Type.LIST, 12);
    output.writeListBegin(Thrift.Type.STRING, this.classification.length);
    for (var iter46 in this.classification)
    {
      if (this.classification.hasOwnProperty(iter46))
      {
        iter46 = this.classification[iter46];
        output.writeString(iter46);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.journal !== null && this.journal !== undefined) {
    output.writeFieldBegin('journal', Thrift.Type.STRING, 13);
    output.writeString(this.journal);
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.LIST, 14);
    output.writeListBegin(Thrift.Type.STRING, this.geohash.length);
    for (var iter47 in this.geohash)
    {
      if (this.geohash.hasOwnProperty(iter47))
      {
        iter47 = this.geohash[iter47];
        output.writeString(iter47);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Author2 = function(args) {
  this.name = null;
  this.org = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.org !== undefined && args.org !== null) {
      this.org = args.org;
    }
  }
};
Author2.prototype = {};
Author2.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.org = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Author2.prototype.write = function(output) {
  output.writeStructBegin('Author2');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.org !== null && this.org !== undefined) {
    output.writeFieldBegin('org', Thrift.Type.STRING, 2);
    output.writeString(this.org);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Document2 = function(args) {
  this.id = null;
  this.type = null;
  this.authors = null;
  this.title = null;
  this.summary = null;
  this.year = null;
  this.date = null;
  this.source = null;
  this.publisher = null;
  this.volume = null;
  this.issue = null;
  this.issn = null;
  this.isbn = null;
  this.doi = null;
  this.pageStart = null;
  this.pageEnd = null;
  this.lang = null;
  this.urls = null;
  this.keywords = null;
  this.references = null;
  this.fos = null;
  this.geohash = null;
  this.lemmas = null;
  this.assignee = null;
  this.publicationDate = null;
  this.priorityDate = null;
  this.kind = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.authors !== undefined && args.authors !== null) {
      this.authors = Thrift.copyList(args.authors, [Author2]);
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.summary !== undefined && args.summary !== null) {
      this.summary = args.summary;
    }
    if (args.year !== undefined && args.year !== null) {
      this.year = args.year;
    }
    if (args.date !== undefined && args.date !== null) {
      this.date = args.date;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = args.source;
    }
    if (args.publisher !== undefined && args.publisher !== null) {
      this.publisher = args.publisher;
    }
    if (args.volume !== undefined && args.volume !== null) {
      this.volume = args.volume;
    }
    if (args.issue !== undefined && args.issue !== null) {
      this.issue = args.issue;
    }
    if (args.issn !== undefined && args.issn !== null) {
      this.issn = Thrift.copyList(args.issn, [null]);
    }
    if (args.isbn !== undefined && args.isbn !== null) {
      this.isbn = Thrift.copyList(args.isbn, [null]);
    }
    if (args.doi !== undefined && args.doi !== null) {
      this.doi = args.doi;
    }
    if (args.pageStart !== undefined && args.pageStart !== null) {
      this.pageStart = args.pageStart;
    }
    if (args.pageEnd !== undefined && args.pageEnd !== null) {
      this.pageEnd = args.pageEnd;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
    if (args.urls !== undefined && args.urls !== null) {
      this.urls = Thrift.copyList(args.urls, [null]);
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.references !== undefined && args.references !== null) {
      this.references = Thrift.copyList(args.references, [null]);
    }
    if (args.fos !== undefined && args.fos !== null) {
      this.fos = Thrift.copyList(args.fos, [null]);
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = Thrift.copyList(args.geohash, [null]);
    }
    if (args.lemmas !== undefined && args.lemmas !== null) {
      this.lemmas = Thrift.copyList(args.lemmas, [null]);
    }
    if (args.assignee !== undefined && args.assignee !== null) {
      this.assignee = Thrift.copyList(args.assignee, [Author2]);
    }
    if (args.publicationDate !== undefined && args.publicationDate !== null) {
      this.publicationDate = args.publicationDate;
    }
    if (args.priorityDate !== undefined && args.priorityDate !== null) {
      this.priorityDate = args.priorityDate;
    }
    if (args.kind !== undefined && args.kind !== null) {
      this.kind = args.kind;
    }
  }
};
Document2.prototype = {};
Document2.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.authors = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new Author2();
          elem54.read(input);
          this.authors.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.summary = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.year = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.date = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.source = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.publisher = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.volume = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.issue = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.LIST) {
        var _size55 = 0;
        var _rtmp359;
        this.issn = [];
        var _etype58 = 0;
        _rtmp359 = input.readListBegin();
        _etype58 = _rtmp359.etype;
        _size55 = _rtmp359.size;
        for (var _i60 = 0; _i60 < _size55; ++_i60)
        {
          var elem61 = null;
          elem61 = input.readString().value;
          this.issn.push(elem61);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.LIST) {
        var _size62 = 0;
        var _rtmp366;
        this.isbn = [];
        var _etype65 = 0;
        _rtmp366 = input.readListBegin();
        _etype65 = _rtmp366.etype;
        _size62 = _rtmp366.size;
        for (var _i67 = 0; _i67 < _size62; ++_i67)
        {
          var elem68 = null;
          elem68 = input.readString().value;
          this.isbn.push(elem68);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.doi = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.I32) {
        this.pageStart = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.I32) {
        this.pageEnd = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.LIST) {
        var _size69 = 0;
        var _rtmp373;
        this.urls = [];
        var _etype72 = 0;
        _rtmp373 = input.readListBegin();
        _etype72 = _rtmp373.etype;
        _size69 = _rtmp373.size;
        for (var _i74 = 0; _i74 < _size69; ++_i74)
        {
          var elem75 = null;
          elem75 = input.readString().value;
          this.urls.push(elem75);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.LIST) {
        var _size76 = 0;
        var _rtmp380;
        this.keywords = [];
        var _etype79 = 0;
        _rtmp380 = input.readListBegin();
        _etype79 = _rtmp380.etype;
        _size76 = _rtmp380.size;
        for (var _i81 = 0; _i81 < _size76; ++_i81)
        {
          var elem82 = null;
          elem82 = input.readString().value;
          this.keywords.push(elem82);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.LIST) {
        var _size83 = 0;
        var _rtmp387;
        this.references = [];
        var _etype86 = 0;
        _rtmp387 = input.readListBegin();
        _etype86 = _rtmp387.etype;
        _size83 = _rtmp387.size;
        for (var _i88 = 0; _i88 < _size83; ++_i88)
        {
          var elem89 = null;
          elem89 = input.readString().value;
          this.references.push(elem89);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.LIST) {
        var _size90 = 0;
        var _rtmp394;
        this.fos = [];
        var _etype93 = 0;
        _rtmp394 = input.readListBegin();
        _etype93 = _rtmp394.etype;
        _size90 = _rtmp394.size;
        for (var _i95 = 0; _i95 < _size90; ++_i95)
        {
          var elem96 = null;
          elem96 = input.readString().value;
          this.fos.push(elem96);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.LIST) {
        var _size97 = 0;
        var _rtmp3101;
        this.geohash = [];
        var _etype100 = 0;
        _rtmp3101 = input.readListBegin();
        _etype100 = _rtmp3101.etype;
        _size97 = _rtmp3101.size;
        for (var _i102 = 0; _i102 < _size97; ++_i102)
        {
          var elem103 = null;
          elem103 = input.readString().value;
          this.geohash.push(elem103);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 23:
      if (ftype == Thrift.Type.LIST) {
        var _size104 = 0;
        var _rtmp3108;
        this.lemmas = [];
        var _etype107 = 0;
        _rtmp3108 = input.readListBegin();
        _etype107 = _rtmp3108.etype;
        _size104 = _rtmp3108.size;
        for (var _i109 = 0; _i109 < _size104; ++_i109)
        {
          var elem110 = null;
          elem110 = input.readString().value;
          this.lemmas.push(elem110);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 24:
      if (ftype == Thrift.Type.LIST) {
        var _size111 = 0;
        var _rtmp3115;
        this.assignee = [];
        var _etype114 = 0;
        _rtmp3115 = input.readListBegin();
        _etype114 = _rtmp3115.etype;
        _size111 = _rtmp3115.size;
        for (var _i116 = 0; _i116 < _size111; ++_i116)
        {
          var elem117 = null;
          elem117 = new Author2();
          elem117.read(input);
          this.assignee.push(elem117);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 25:
      if (ftype == Thrift.Type.STRING) {
        this.publicationDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 26:
      if (ftype == Thrift.Type.STRING) {
        this.priorityDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 27:
      if (ftype == Thrift.Type.STRING) {
        this.kind = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Document2.prototype.write = function(output) {
  output.writeStructBegin('Document2');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 2);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.authors !== null && this.authors !== undefined) {
    output.writeFieldBegin('authors', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.authors.length);
    for (var iter118 in this.authors)
    {
      if (this.authors.hasOwnProperty(iter118))
      {
        iter118 = this.authors[iter118];
        iter118.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 4);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.summary !== null && this.summary !== undefined) {
    output.writeFieldBegin('summary', Thrift.Type.STRING, 5);
    output.writeString(this.summary);
    output.writeFieldEnd();
  }
  if (this.year !== null && this.year !== undefined) {
    output.writeFieldBegin('year', Thrift.Type.STRING, 6);
    output.writeString(this.year);
    output.writeFieldEnd();
  }
  if (this.date !== null && this.date !== undefined) {
    output.writeFieldBegin('date', Thrift.Type.STRING, 7);
    output.writeString(this.date);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRING, 8);
    output.writeString(this.source);
    output.writeFieldEnd();
  }
  if (this.publisher !== null && this.publisher !== undefined) {
    output.writeFieldBegin('publisher', Thrift.Type.STRING, 9);
    output.writeString(this.publisher);
    output.writeFieldEnd();
  }
  if (this.volume !== null && this.volume !== undefined) {
    output.writeFieldBegin('volume', Thrift.Type.STRING, 10);
    output.writeString(this.volume);
    output.writeFieldEnd();
  }
  if (this.issue !== null && this.issue !== undefined) {
    output.writeFieldBegin('issue', Thrift.Type.STRING, 11);
    output.writeString(this.issue);
    output.writeFieldEnd();
  }
  if (this.issn !== null && this.issn !== undefined) {
    output.writeFieldBegin('issn', Thrift.Type.LIST, 12);
    output.writeListBegin(Thrift.Type.STRING, this.issn.length);
    for (var iter119 in this.issn)
    {
      if (this.issn.hasOwnProperty(iter119))
      {
        iter119 = this.issn[iter119];
        output.writeString(iter119);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.isbn !== null && this.isbn !== undefined) {
    output.writeFieldBegin('isbn', Thrift.Type.LIST, 13);
    output.writeListBegin(Thrift.Type.STRING, this.isbn.length);
    for (var iter120 in this.isbn)
    {
      if (this.isbn.hasOwnProperty(iter120))
      {
        iter120 = this.isbn[iter120];
        output.writeString(iter120);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.doi !== null && this.doi !== undefined) {
    output.writeFieldBegin('doi', Thrift.Type.STRING, 14);
    output.writeString(this.doi);
    output.writeFieldEnd();
  }
  if (this.pageStart !== null && this.pageStart !== undefined) {
    output.writeFieldBegin('pageStart', Thrift.Type.I32, 15);
    output.writeI32(this.pageStart);
    output.writeFieldEnd();
  }
  if (this.pageEnd !== null && this.pageEnd !== undefined) {
    output.writeFieldBegin('pageEnd', Thrift.Type.I32, 16);
    output.writeI32(this.pageEnd);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 17);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  if (this.urls !== null && this.urls !== undefined) {
    output.writeFieldBegin('urls', Thrift.Type.LIST, 18);
    output.writeListBegin(Thrift.Type.STRING, this.urls.length);
    for (var iter121 in this.urls)
    {
      if (this.urls.hasOwnProperty(iter121))
      {
        iter121 = this.urls[iter121];
        output.writeString(iter121);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 19);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter122 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter122))
      {
        iter122 = this.keywords[iter122];
        output.writeString(iter122);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.references !== null && this.references !== undefined) {
    output.writeFieldBegin('references', Thrift.Type.LIST, 20);
    output.writeListBegin(Thrift.Type.STRING, this.references.length);
    for (var iter123 in this.references)
    {
      if (this.references.hasOwnProperty(iter123))
      {
        iter123 = this.references[iter123];
        output.writeString(iter123);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.fos !== null && this.fos !== undefined) {
    output.writeFieldBegin('fos', Thrift.Type.LIST, 21);
    output.writeListBegin(Thrift.Type.STRING, this.fos.length);
    for (var iter124 in this.fos)
    {
      if (this.fos.hasOwnProperty(iter124))
      {
        iter124 = this.fos[iter124];
        output.writeString(iter124);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.LIST, 22);
    output.writeListBegin(Thrift.Type.STRING, this.geohash.length);
    for (var iter125 in this.geohash)
    {
      if (this.geohash.hasOwnProperty(iter125))
      {
        iter125 = this.geohash[iter125];
        output.writeString(iter125);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.lemmas !== null && this.lemmas !== undefined) {
    output.writeFieldBegin('lemmas', Thrift.Type.LIST, 23);
    output.writeListBegin(Thrift.Type.STRING, this.lemmas.length);
    for (var iter126 in this.lemmas)
    {
      if (this.lemmas.hasOwnProperty(iter126))
      {
        iter126 = this.lemmas[iter126];
        output.writeString(iter126);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.assignee !== null && this.assignee !== undefined) {
    output.writeFieldBegin('assignee', Thrift.Type.LIST, 24);
    output.writeListBegin(Thrift.Type.STRUCT, this.assignee.length);
    for (var iter127 in this.assignee)
    {
      if (this.assignee.hasOwnProperty(iter127))
      {
        iter127 = this.assignee[iter127];
        iter127.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.publicationDate !== null && this.publicationDate !== undefined) {
    output.writeFieldBegin('publicationDate', Thrift.Type.STRING, 25);
    output.writeString(this.publicationDate);
    output.writeFieldEnd();
  }
  if (this.priorityDate !== null && this.priorityDate !== undefined) {
    output.writeFieldBegin('priorityDate', Thrift.Type.STRING, 26);
    output.writeString(this.priorityDate);
    output.writeFieldEnd();
  }
  if (this.kind !== null && this.kind !== undefined) {
    output.writeFieldBegin('kind', Thrift.Type.STRING, 27);
    output.writeString(this.kind);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

TwitterUser = function(args) {
  this.id = null;
  this.name = null;
  this.screenName = null;
  this.description = null;
  this.location = null;
  this.geohash = null;
  this.url = null;
  this.profileUrl = null;
  this.followersCount = null;
  this.friendsCount = null;
  this.listedCount = null;
  this.favouritesCount = null;
  this.statusesCount = null;
  this.createdTime = null;
  this.lang = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.screenName !== undefined && args.screenName !== null) {
      this.screenName = args.screenName;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = args.geohash;
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
    if (args.profileUrl !== undefined && args.profileUrl !== null) {
      this.profileUrl = args.profileUrl;
    }
    if (args.followersCount !== undefined && args.followersCount !== null) {
      this.followersCount = args.followersCount;
    }
    if (args.friendsCount !== undefined && args.friendsCount !== null) {
      this.friendsCount = args.friendsCount;
    }
    if (args.listedCount !== undefined && args.listedCount !== null) {
      this.listedCount = args.listedCount;
    }
    if (args.favouritesCount !== undefined && args.favouritesCount !== null) {
      this.favouritesCount = args.favouritesCount;
    }
    if (args.statusesCount !== undefined && args.statusesCount !== null) {
      this.statusesCount = args.statusesCount;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
  }
};
TwitterUser.prototype = {};
TwitterUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.screenName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.geohash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.profileUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.followersCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I32) {
        this.friendsCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.I32) {
        this.listedCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.I32) {
        this.favouritesCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.statusesCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.createdTime = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TwitterUser.prototype.write = function(output) {
  output.writeStructBegin('TwitterUser');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.screenName !== null && this.screenName !== undefined) {
    output.writeFieldBegin('screenName', Thrift.Type.STRING, 3);
    output.writeString(this.screenName);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 4);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 5);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.STRING, 6);
    output.writeString(this.geohash);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 7);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  if (this.profileUrl !== null && this.profileUrl !== undefined) {
    output.writeFieldBegin('profileUrl', Thrift.Type.STRING, 8);
    output.writeString(this.profileUrl);
    output.writeFieldEnd();
  }
  if (this.followersCount !== null && this.followersCount !== undefined) {
    output.writeFieldBegin('followersCount', Thrift.Type.I32, 9);
    output.writeI32(this.followersCount);
    output.writeFieldEnd();
  }
  if (this.friendsCount !== null && this.friendsCount !== undefined) {
    output.writeFieldBegin('friendsCount', Thrift.Type.I32, 11);
    output.writeI32(this.friendsCount);
    output.writeFieldEnd();
  }
  if (this.listedCount !== null && this.listedCount !== undefined) {
    output.writeFieldBegin('listedCount', Thrift.Type.I32, 12);
    output.writeI32(this.listedCount);
    output.writeFieldEnd();
  }
  if (this.favouritesCount !== null && this.favouritesCount !== undefined) {
    output.writeFieldBegin('favouritesCount', Thrift.Type.I32, 13);
    output.writeI32(this.favouritesCount);
    output.writeFieldEnd();
  }
  if (this.statusesCount !== null && this.statusesCount !== undefined) {
    output.writeFieldBegin('statusesCount', Thrift.Type.I32, 14);
    output.writeI32(this.statusesCount);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.STRING, 15);
    output.writeString(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 16);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Tweet = function(args) {
  this.id = null;
  this.tweetText = null;
  this.source = null;
  this.location = null;
  this.geohash = null;
  this.lang = null;
  this.quoteCount = null;
  this.replyCount = null;
  this.retweetCount = null;
  this.favoriteCount = null;
  this.userId = null;
  this.retweetId = null;
  this.replyToStatusId = null;
  this.replyToUserId = null;
  this.replyToScreenName = null;
  this.createdTime = null;
  this.channels = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.tweetText !== undefined && args.tweetText !== null) {
      this.tweetText = args.tweetText;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = args.source;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = args.geohash;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
    if (args.quoteCount !== undefined && args.quoteCount !== null) {
      this.quoteCount = args.quoteCount;
    }
    if (args.replyCount !== undefined && args.replyCount !== null) {
      this.replyCount = args.replyCount;
    }
    if (args.retweetCount !== undefined && args.retweetCount !== null) {
      this.retweetCount = args.retweetCount;
    }
    if (args.favoriteCount !== undefined && args.favoriteCount !== null) {
      this.favoriteCount = args.favoriteCount;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.retweetId !== undefined && args.retweetId !== null) {
      this.retweetId = args.retweetId;
    }
    if (args.replyToStatusId !== undefined && args.replyToStatusId !== null) {
      this.replyToStatusId = args.replyToStatusId;
    }
    if (args.replyToUserId !== undefined && args.replyToUserId !== null) {
      this.replyToUserId = args.replyToUserId;
    }
    if (args.replyToScreenName !== undefined && args.replyToScreenName !== null) {
      this.replyToScreenName = args.replyToScreenName;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.channels !== undefined && args.channels !== null) {
      this.channels = Thrift.copyList(args.channels, [null]);
    }
  }
};
Tweet.prototype = {};
Tweet.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.tweetText = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.source = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.geohash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.quoteCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.replyCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.retweetCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I32) {
        this.favoriteCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.retweetId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.replyToStatusId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.replyToUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.replyToScreenName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.createdTime = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.LIST) {
        var _size128 = 0;
        var _rtmp3132;
        this.channels = [];
        var _etype131 = 0;
        _rtmp3132 = input.readListBegin();
        _etype131 = _rtmp3132.etype;
        _size128 = _rtmp3132.size;
        for (var _i133 = 0; _i133 < _size128; ++_i133)
        {
          var elem134 = null;
          elem134 = input.readString().value;
          this.channels.push(elem134);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Tweet.prototype.write = function(output) {
  output.writeStructBegin('Tweet');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.tweetText !== null && this.tweetText !== undefined) {
    output.writeFieldBegin('tweetText', Thrift.Type.STRING, 2);
    output.writeString(this.tweetText);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRING, 3);
    output.writeString(this.source);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 4);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.STRING, 5);
    output.writeString(this.geohash);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 6);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  if (this.quoteCount !== null && this.quoteCount !== undefined) {
    output.writeFieldBegin('quoteCount', Thrift.Type.I32, 7);
    output.writeI32(this.quoteCount);
    output.writeFieldEnd();
  }
  if (this.replyCount !== null && this.replyCount !== undefined) {
    output.writeFieldBegin('replyCount', Thrift.Type.I32, 8);
    output.writeI32(this.replyCount);
    output.writeFieldEnd();
  }
  if (this.retweetCount !== null && this.retweetCount !== undefined) {
    output.writeFieldBegin('retweetCount', Thrift.Type.I32, 9);
    output.writeI32(this.retweetCount);
    output.writeFieldEnd();
  }
  if (this.favoriteCount !== null && this.favoriteCount !== undefined) {
    output.writeFieldBegin('favoriteCount', Thrift.Type.I32, 10);
    output.writeI32(this.favoriteCount);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 11);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.retweetId !== null && this.retweetId !== undefined) {
    output.writeFieldBegin('retweetId', Thrift.Type.STRING, 12);
    output.writeString(this.retweetId);
    output.writeFieldEnd();
  }
  if (this.replyToStatusId !== null && this.replyToStatusId !== undefined) {
    output.writeFieldBegin('replyToStatusId', Thrift.Type.STRING, 13);
    output.writeString(this.replyToStatusId);
    output.writeFieldEnd();
  }
  if (this.replyToUserId !== null && this.replyToUserId !== undefined) {
    output.writeFieldBegin('replyToUserId', Thrift.Type.STRING, 14);
    output.writeString(this.replyToUserId);
    output.writeFieldEnd();
  }
  if (this.replyToScreenName !== null && this.replyToScreenName !== undefined) {
    output.writeFieldBegin('replyToScreenName', Thrift.Type.STRING, 15);
    output.writeString(this.replyToScreenName);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.STRING, 16);
    output.writeString(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.channels !== null && this.channels !== undefined) {
    output.writeFieldBegin('channels', Thrift.Type.LIST, 17);
    output.writeListBegin(Thrift.Type.STRING, this.channels.length);
    for (var iter135 in this.channels)
    {
      if (this.channels.hasOwnProperty(iter135))
      {
        iter135 = this.channels[iter135];
        output.writeString(iter135);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Range = function(args) {
  this.type = null;
  this.gte = null;
  this.lte = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.gte !== undefined && args.gte !== null) {
      this.gte = args.gte;
    }
    if (args.lte !== undefined && args.lte !== null) {
      this.lte = args.lte;
    }
  }
};
Range.prototype = {};
Range.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.gte = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lte = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Range.prototype.write = function(output) {
  output.writeStructBegin('Range');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.gte !== null && this.gte !== undefined) {
    output.writeFieldBegin('gte', Thrift.Type.STRING, 2);
    output.writeString(this.gte);
    output.writeFieldEnd();
  }
  if (this.lte !== null && this.lte !== undefined) {
    output.writeFieldBegin('lte', Thrift.Type.STRING, 3);
    output.writeString(this.lte);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GeohashRange = function(args) {
  this.tl = null;
  this.br = null;
  if (args) {
    if (args.tl !== undefined && args.tl !== null) {
      this.tl = args.tl;
    }
    if (args.br !== undefined && args.br !== null) {
      this.br = args.br;
    }
  }
};
GeohashRange.prototype = {};
GeohashRange.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.tl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.br = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GeohashRange.prototype.write = function(output) {
  output.writeStructBegin('GeohashRange');
  if (this.tl !== null && this.tl !== undefined) {
    output.writeFieldBegin('tl', Thrift.Type.STRING, 1);
    output.writeString(this.tl);
    output.writeFieldEnd();
  }
  if (this.br !== null && this.br !== undefined) {
    output.writeFieldBegin('br', Thrift.Type.STRING, 2);
    output.writeString(this.br);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchData = function(args) {
  this.value = null;
  this.type = null;
  this.operation = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.operation !== undefined && args.operation !== null) {
      this.operation = args.operation;
    }
  }
};
SearchData.prototype = {};
SearchData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.operation = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchData.prototype.write = function(output) {
  output.writeStructBegin('SearchData');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.operation !== null && this.operation !== undefined) {
    output.writeFieldBegin('operation', Thrift.Type.I32, 3);
    output.writeI32(this.operation);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchEsReq = function(args) {
  this.indexName = null;
  this.start = null;
  this.size = null;
  this.type = null;
  this.sortType = null;
  this.sortOrder = null;
  this.range = null;
  this.searchType = null;
  this.geohashRange = null;
  this.search = null;
  this.aggregation = null;
  if (args) {
    if (args.indexName !== undefined && args.indexName !== null) {
      this.indexName = args.indexName;
    }
    if (args.start !== undefined && args.start !== null) {
      this.start = args.start;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = Thrift.copyList(args.type, [null]);
    }
    if (args.sortType !== undefined && args.sortType !== null) {
      this.sortType = args.sortType;
    }
    if (args.sortOrder !== undefined && args.sortOrder !== null) {
      this.sortOrder = args.sortOrder;
    }
    if (args.range !== undefined && args.range !== null) {
      this.range = new Range(args.range);
    }
    if (args.searchType !== undefined && args.searchType !== null) {
      this.searchType = args.searchType;
    }
    if (args.geohashRange !== undefined && args.geohashRange !== null) {
      this.geohashRange = new GeohashRange(args.geohashRange);
    }
    if (args.search !== undefined && args.search !== null) {
      this.search = Thrift.copyList(args.search, [SearchData]);
    }
    if (args.aggregation !== undefined && args.aggregation !== null) {
      this.aggregation = Thrift.copyList(args.aggregation, [null]);
    }
  }
};
SearchEsReq.prototype = {};
SearchEsReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.indexName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.start = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.size = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size136 = 0;
        var _rtmp3140;
        this.type = [];
        var _etype139 = 0;
        _rtmp3140 = input.readListBegin();
        _etype139 = _rtmp3140.etype;
        _size136 = _rtmp3140.size;
        for (var _i141 = 0; _i141 < _size136; ++_i141)
        {
          var elem142 = null;
          elem142 = input.readString().value;
          this.type.push(elem142);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.sortType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.sortOrder = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.range = new Range();
        this.range.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.searchType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.geohashRange = new GeohashRange();
        this.geohashRange.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.LIST) {
        var _size143 = 0;
        var _rtmp3147;
        this.search = [];
        var _etype146 = 0;
        _rtmp3147 = input.readListBegin();
        _etype146 = _rtmp3147.etype;
        _size143 = _rtmp3147.size;
        for (var _i148 = 0; _i148 < _size143; ++_i148)
        {
          var elem149 = null;
          elem149 = new SearchData();
          elem149.read(input);
          this.search.push(elem149);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.LIST) {
        var _size150 = 0;
        var _rtmp3154;
        this.aggregation = [];
        var _etype153 = 0;
        _rtmp3154 = input.readListBegin();
        _etype153 = _rtmp3154.etype;
        _size150 = _rtmp3154.size;
        for (var _i155 = 0; _i155 < _size150; ++_i155)
        {
          var elem156 = null;
          elem156 = input.readI32().value;
          this.aggregation.push(elem156);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchEsReq.prototype.write = function(output) {
  output.writeStructBegin('SearchEsReq');
  if (this.indexName !== null && this.indexName !== undefined) {
    output.writeFieldBegin('indexName', Thrift.Type.STRING, 1);
    output.writeString(this.indexName);
    output.writeFieldEnd();
  }
  if (this.start !== null && this.start !== undefined) {
    output.writeFieldBegin('start', Thrift.Type.I32, 2);
    output.writeI32(this.start);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I32, 3);
    output.writeI32(this.size);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRING, this.type.length);
    for (var iter157 in this.type)
    {
      if (this.type.hasOwnProperty(iter157))
      {
        iter157 = this.type[iter157];
        output.writeString(iter157);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sortType !== null && this.sortType !== undefined) {
    output.writeFieldBegin('sortType', Thrift.Type.STRING, 8);
    output.writeString(this.sortType);
    output.writeFieldEnd();
  }
  if (this.sortOrder !== null && this.sortOrder !== undefined) {
    output.writeFieldBegin('sortOrder', Thrift.Type.STRING, 9);
    output.writeString(this.sortOrder);
    output.writeFieldEnd();
  }
  if (this.range !== null && this.range !== undefined) {
    output.writeFieldBegin('range', Thrift.Type.STRUCT, 10);
    this.range.write(output);
    output.writeFieldEnd();
  }
  if (this.searchType !== null && this.searchType !== undefined) {
    output.writeFieldBegin('searchType', Thrift.Type.STRING, 11);
    output.writeString(this.searchType);
    output.writeFieldEnd();
  }
  if (this.geohashRange !== null && this.geohashRange !== undefined) {
    output.writeFieldBegin('geohashRange', Thrift.Type.STRUCT, 12);
    this.geohashRange.write(output);
    output.writeFieldEnd();
  }
  if (this.search !== null && this.search !== undefined) {
    output.writeFieldBegin('search', Thrift.Type.LIST, 13);
    output.writeListBegin(Thrift.Type.STRUCT, this.search.length);
    for (var iter158 in this.search)
    {
      if (this.search.hasOwnProperty(iter158))
      {
        iter158 = this.search[iter158];
        iter158.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.aggregation !== null && this.aggregation !== undefined) {
    output.writeFieldBegin('aggregation', Thrift.Type.LIST, 14);
    output.writeListBegin(Thrift.Type.I32, this.aggregation.length);
    for (var iter159 in this.aggregation)
    {
      if (this.aggregation.hasOwnProperty(iter159))
      {
        iter159 = this.aggregation[iter159];
        output.writeI32(iter159);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AggregationKey = function(args) {
  this.key = null;
  this.docCount = null;
  if (args) {
    if (args.key !== undefined && args.key !== null) {
      this.key = args.key;
    }
    if (args.docCount !== undefined && args.docCount !== null) {
      this.docCount = args.docCount;
    }
  }
};
AggregationKey.prototype = {};
AggregationKey.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.key = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.docCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AggregationKey.prototype.write = function(output) {
  output.writeStructBegin('AggregationKey');
  if (this.key !== null && this.key !== undefined) {
    output.writeFieldBegin('key', Thrift.Type.STRING, 1);
    output.writeString(this.key);
    output.writeFieldEnd();
  }
  if (this.docCount !== null && this.docCount !== undefined) {
    output.writeFieldBegin('docCount', Thrift.Type.I32, 2);
    output.writeI32(this.docCount);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

AggregationResult = function(args) {
  this.type = null;
  this.keys = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.keys !== undefined && args.keys !== null) {
      this.keys = Thrift.copyList(args.keys, [AggregationKey]);
    }
  }
};
AggregationResult.prototype = {};
AggregationResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size160 = 0;
        var _rtmp3164;
        this.keys = [];
        var _etype163 = 0;
        _rtmp3164 = input.readListBegin();
        _etype163 = _rtmp3164.etype;
        _size160 = _rtmp3164.size;
        for (var _i165 = 0; _i165 < _size160; ++_i165)
        {
          var elem166 = null;
          elem166 = new AggregationKey();
          elem166.read(input);
          this.keys.push(elem166);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AggregationResult.prototype.write = function(output) {
  output.writeStructBegin('AggregationResult');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.keys !== null && this.keys !== undefined) {
    output.writeFieldBegin('keys', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.keys.length);
    for (var iter167 in this.keys)
    {
      if (this.keys.hasOwnProperty(iter167))
      {
        iter167 = this.keys[iter167];
        iter167.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SearchEsResp = function(args) {
  this.documents = null;
  this.total = null;
  this.start = null;
  this.documents2 = null;
  this.tweets = null;
  this.twitterUsers = null;
  this.aggResults = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [Document]);
    }
    if (args.total !== undefined && args.total !== null) {
      this.total = args.total;
    }
    if (args.start !== undefined && args.start !== null) {
      this.start = args.start;
    }
    if (args.documents2 !== undefined && args.documents2 !== null) {
      this.documents2 = Thrift.copyList(args.documents2, [Document2]);
    }
    if (args.tweets !== undefined && args.tweets !== null) {
      this.tweets = Thrift.copyList(args.tweets, [Tweet]);
    }
    if (args.twitterUsers !== undefined && args.twitterUsers !== null) {
      this.twitterUsers = Thrift.copyList(args.twitterUsers, [TwitterUser]);
    }
    if (args.aggResults !== undefined && args.aggResults !== null) {
      this.aggResults = Thrift.copyList(args.aggResults, [AggregationResult]);
    }
  }
};
SearchEsResp.prototype = {};
SearchEsResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size168 = 0;
        var _rtmp3172;
        this.documents = [];
        var _etype171 = 0;
        _rtmp3172 = input.readListBegin();
        _etype171 = _rtmp3172.etype;
        _size168 = _rtmp3172.size;
        for (var _i173 = 0; _i173 < _size168; ++_i173)
        {
          var elem174 = null;
          elem174 = new Document();
          elem174.read(input);
          this.documents.push(elem174);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.total = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.start = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size175 = 0;
        var _rtmp3179;
        this.documents2 = [];
        var _etype178 = 0;
        _rtmp3179 = input.readListBegin();
        _etype178 = _rtmp3179.etype;
        _size175 = _rtmp3179.size;
        for (var _i180 = 0; _i180 < _size175; ++_i180)
        {
          var elem181 = null;
          elem181 = new Document2();
          elem181.read(input);
          this.documents2.push(elem181);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size182 = 0;
        var _rtmp3186;
        this.tweets = [];
        var _etype185 = 0;
        _rtmp3186 = input.readListBegin();
        _etype185 = _rtmp3186.etype;
        _size182 = _rtmp3186.size;
        for (var _i187 = 0; _i187 < _size182; ++_i187)
        {
          var elem188 = null;
          elem188 = new Tweet();
          elem188.read(input);
          this.tweets.push(elem188);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size189 = 0;
        var _rtmp3193;
        this.twitterUsers = [];
        var _etype192 = 0;
        _rtmp3193 = input.readListBegin();
        _etype192 = _rtmp3193.etype;
        _size189 = _rtmp3193.size;
        for (var _i194 = 0; _i194 < _size189; ++_i194)
        {
          var elem195 = null;
          elem195 = new TwitterUser();
          elem195.read(input);
          this.twitterUsers.push(elem195);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size196 = 0;
        var _rtmp3200;
        this.aggResults = [];
        var _etype199 = 0;
        _rtmp3200 = input.readListBegin();
        _etype199 = _rtmp3200.etype;
        _size196 = _rtmp3200.size;
        for (var _i201 = 0; _i201 < _size196; ++_i201)
        {
          var elem202 = null;
          elem202 = new AggregationResult();
          elem202.read(input);
          this.aggResults.push(elem202);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SearchEsResp.prototype.write = function(output) {
  output.writeStructBegin('SearchEsResp');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter203 in this.documents)
    {
      if (this.documents.hasOwnProperty(iter203))
      {
        iter203 = this.documents[iter203];
        iter203.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.total !== null && this.total !== undefined) {
    output.writeFieldBegin('total', Thrift.Type.I32, 2);
    output.writeI32(this.total);
    output.writeFieldEnd();
  }
  if (this.start !== null && this.start !== undefined) {
    output.writeFieldBegin('start', Thrift.Type.I32, 3);
    output.writeI32(this.start);
    output.writeFieldEnd();
  }
  if (this.documents2 !== null && this.documents2 !== undefined) {
    output.writeFieldBegin('documents2', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents2.length);
    for (var iter204 in this.documents2)
    {
      if (this.documents2.hasOwnProperty(iter204))
      {
        iter204 = this.documents2[iter204];
        iter204.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.tweets !== null && this.tweets !== undefined) {
    output.writeFieldBegin('tweets', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.tweets.length);
    for (var iter205 in this.tweets)
    {
      if (this.tweets.hasOwnProperty(iter205))
      {
        iter205 = this.tweets[iter205];
        iter205.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.twitterUsers !== null && this.twitterUsers !== undefined) {
    output.writeFieldBegin('twitterUsers', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.twitterUsers.length);
    for (var iter206 in this.twitterUsers)
    {
      if (this.twitterUsers.hasOwnProperty(iter206))
      {
        iter206 = this.twitterUsers[iter206];
        iter206.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.aggResults !== null && this.aggResults !== undefined) {
    output.writeFieldBegin('aggResults', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.aggResults.length);
    for (var iter207 in this.aggResults)
    {
      if (this.aggResults.hasOwnProperty(iter207))
      {
        iter207 = this.aggResults[iter207];
        iter207.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetEsReq = function(args) {
  this.indexName = null;
  this.type = null;
  this.ids = null;
  if (args) {
    if (args.indexName !== undefined && args.indexName !== null) {
      this.indexName = args.indexName;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.ids !== undefined && args.ids !== null) {
      this.ids = Thrift.copyList(args.ids, [null]);
    }
  }
};
GetEsReq.prototype = {};
GetEsReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.indexName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size208 = 0;
        var _rtmp3212;
        this.ids = [];
        var _etype211 = 0;
        _rtmp3212 = input.readListBegin();
        _etype211 = _rtmp3212.etype;
        _size208 = _rtmp3212.size;
        for (var _i213 = 0; _i213 < _size208; ++_i213)
        {
          var elem214 = null;
          elem214 = input.readString().value;
          this.ids.push(elem214);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEsReq.prototype.write = function(output) {
  output.writeStructBegin('GetEsReq');
  if (this.indexName !== null && this.indexName !== undefined) {
    output.writeFieldBegin('indexName', Thrift.Type.STRING, 1);
    output.writeString(this.indexName);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 2);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.ids !== null && this.ids !== undefined) {
    output.writeFieldBegin('ids', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRING, this.ids.length);
    for (var iter215 in this.ids)
    {
      if (this.ids.hasOwnProperty(iter215))
      {
        iter215 = this.ids[iter215];
        output.writeString(iter215);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetEsResp = function(args) {
  this.documents = null;
  this.tweets = null;
  this.twitterUsers = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [Document2]);
    }
    if (args.tweets !== undefined && args.tweets !== null) {
      this.tweets = Thrift.copyList(args.tweets, [Tweet]);
    }
    if (args.twitterUsers !== undefined && args.twitterUsers !== null) {
      this.twitterUsers = Thrift.copyList(args.twitterUsers, [TwitterUser]);
    }
  }
};
GetEsResp.prototype = {};
GetEsResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size216 = 0;
        var _rtmp3220;
        this.documents = [];
        var _etype219 = 0;
        _rtmp3220 = input.readListBegin();
        _etype219 = _rtmp3220.etype;
        _size216 = _rtmp3220.size;
        for (var _i221 = 0; _i221 < _size216; ++_i221)
        {
          var elem222 = null;
          elem222 = new Document2();
          elem222.read(input);
          this.documents.push(elem222);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size223 = 0;
        var _rtmp3227;
        this.tweets = [];
        var _etype226 = 0;
        _rtmp3227 = input.readListBegin();
        _etype226 = _rtmp3227.etype;
        _size223 = _rtmp3227.size;
        for (var _i228 = 0; _i228 < _size223; ++_i228)
        {
          var elem229 = null;
          elem229 = new Tweet();
          elem229.read(input);
          this.tweets.push(elem229);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size230 = 0;
        var _rtmp3234;
        this.twitterUsers = [];
        var _etype233 = 0;
        _rtmp3234 = input.readListBegin();
        _etype233 = _rtmp3234.etype;
        _size230 = _rtmp3234.size;
        for (var _i235 = 0; _i235 < _size230; ++_i235)
        {
          var elem236 = null;
          elem236 = new TwitterUser();
          elem236.read(input);
          this.twitterUsers.push(elem236);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEsResp.prototype.write = function(output) {
  output.writeStructBegin('GetEsResp');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter237 in this.documents)
    {
      if (this.documents.hasOwnProperty(iter237))
      {
        iter237 = this.documents[iter237];
        iter237.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.tweets !== null && this.tweets !== undefined) {
    output.writeFieldBegin('tweets', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.tweets.length);
    for (var iter238 in this.tweets)
    {
      if (this.tweets.hasOwnProperty(iter238))
      {
        iter238 = this.tweets[iter238];
        iter238.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.twitterUsers !== null && this.twitterUsers !== undefined) {
    output.writeFieldBegin('twitterUsers', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.twitterUsers.length);
    for (var iter239 in this.twitterUsers)
    {
      if (this.twitterUsers.hasOwnProperty(iter239))
      {
        iter239 = this.twitterUsers[iter239];
        iter239.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

