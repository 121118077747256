//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


MsgType = {
  'ERROR' : -1,
  'EMPTY' : 0,
  'LOGIN_REQ' : 1,
  'LOGIN_RESP' : 2,
  'LOGOUT' : 3,
  'GET_SESSION_REQ' : 4,
  'GET_SESSION_RESP' : 5,
  'UPDATE_USER' : 100,
  'DELETE_USER' : 101,
  'UPDATE_PASSWD' : 102,
  'GET_USER_REQ' : 103,
  'GET_USER_RESP' : 104,
  'GET_CAPTCHA_REQ' : 105,
  'GET_CAPTCHA_RESP' : 106,
  'SIGNUP_REQ' : 107,
  'SIGNUP_RESP' : 108,
  'UPDATE_ORGANIZATION' : 200,
  'DELETE_ORGANIZATION' : 201,
  'GET_ORGANIZATION_REQ' : 202,
  'GET_ORGANIZATION_RESP' : 203,
  'UPDATE_CASE' : 204,
  'GET_CASE_REQ' : 205,
  'GET_CASE_RESP' : 206,
  'UPDATE_CURRENT_CASE' : 207,
  'UPDATE_SOURCE' : 208,
  'GET_SOURCE_REQ' : 209,
  'GET_SOURCE_RESP' : 210,
  'GET_SOURCE_STAT_REQ' : 211,
  'GET_SOURCE_STAT_RESP' : 212,
  'UPDATE_SCOPE' : 213,
  'GET_SCOPE_REQ' : 214,
  'GET_SCOPE_RESP' : 215,
  'SEND_EMAIL_REQ' : 216,
  'SEND_EMAIL_RESP' : 217,
  'UPDATE_TWITTER_CHANNEL' : 218,
  'GET_TWITTER_CHANNEL_REQ' : 219,
  'GET_TWITTER_CHANNEL_RESP' : 220,
  'UPDATE_CURRENT_TWITTER_CHANNEL' : 221,
  'UPDATE_TWITTER_CHANNEL_REQ' : 222,
  'UPDATE_TWITTER_CHANNEL_RESP' : 223,
  'UPDATE_UI_TEMPLATE_REQ' : 224,
  'UPDATE_UI_TEMPLATE_RESP' : 225,
  'GET_UI_TEMPLATE_REQ' : 226,
  'GET_UI_TEMPLATE_RESP' : 227,
  'GET_UI_TEMPLATES_REQ' : 228,
  'GET_UI_TEMPLATES_RESP' : 229,
  'DELETE_UI_TEMPLATE_REQ' : 230,
  'DELETE_UI_TEMPLATE_RESP' : 231,
  'CHECK_NEWS_FEED_REQ' : 232,
  'CHECK_NEWS_FEED_RESP' : 233,
  'GET_CHECKOUT_SESSION_REQ' : 234,
  'GET_CHECKOUT_SESSION_RESP' : 235,
  'SET_CHECKOUT_STATUS_REQ' : 236,
  'SET_CHECKOUT_STATUS_RESP' : 237,
  'SEARCH_ES_REQ' : 300,
  'SEARCH_ES_RESP' : 301,
  'GET_ES_REQ' : 302,
  'GET_ES_RESP' : 303,
  'SEARCH_ORGANIZATION_REQ' : 400,
  'SEARCH_ORGANIZATION_RESP' : 401,
  'SEARCH_VOCABULARY_REQ' : 402,
  'SEARCH_VOCABULARY_RESP' : 403,
  'SEARCH_VOCABULARY_HISTORY_REQ' : 404,
  'SEARCH_VOCABULARY_HISTORY_RESP' : 405,
  'SEARCH_VOCABULARY2_REQ' : 406,
  'SEARCH_VOCABULARY2_RESP' : 407,
  'PROCESS_WEBSHOT' : 500,
  'GET_WEBSHOT_REQ' : 501,
  'GET_WEBSHOT_RESP' : 502,
  'REINIT_TWITTER_CHANNELS' : 600,
  'CLOSE_TWITTER_CHANNELS' : 601,
  'REINIT_ALL_TWITTER_CHANNELS' : 602,
  'START_ANALYZE_REQ' : 700,
  'START_ANALYZE_RESP' : 701
};
ErrorCode = {
  'GENERIC' : 1,
  'UNKNOWN_MSG_TYPE' : 2,
  'TIMEOUT' : 3
};
Msg = function(args) {
  this.type = null;
  this.value = null;
  this.reqId = null;
  this.sessionId = null;
  this.sessionToken = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.reqId !== undefined && args.reqId !== null) {
      this.reqId = args.reqId;
    }
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = args.sessionId;
    }
    if (args.sessionToken !== undefined && args.sessionToken !== null) {
      this.sessionToken = args.sessionToken;
    }
  }
};
Msg.prototype = {};
Msg.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readBinary().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.reqId = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.sessionId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.sessionToken = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Msg.prototype.write = function(output) {
  output.writeStructBegin('Msg');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 2);
    output.writeBinary(this.value);
    output.writeFieldEnd();
  }
  if (this.reqId !== null && this.reqId !== undefined) {
    output.writeFieldBegin('reqId', Thrift.Type.I32, 3);
    output.writeI32(this.reqId);
    output.writeFieldEnd();
  }
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRING, 4);
    output.writeString(this.sessionId);
    output.writeFieldEnd();
  }
  if (this.sessionToken !== null && this.sessionToken !== undefined) {
    output.writeFieldBegin('sessionToken', Thrift.Type.STRING, 5);
    output.writeString(this.sessionToken);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ErrorMsg = function(args) {
  this.code = null;
  this.msgType = null;
  this.errorDesc = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.msgType !== undefined && args.msgType !== null) {
      this.msgType = args.msgType;
    }
    if (args.errorDesc !== undefined && args.errorDesc !== null) {
      this.errorDesc = args.errorDesc;
    }
  }
};
ErrorMsg.prototype = {};
ErrorMsg.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.code = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.msgType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.errorDesc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ErrorMsg.prototype.write = function(output) {
  output.writeStructBegin('ErrorMsg');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.I32, 1);
    output.writeI32(this.code);
    output.writeFieldEnd();
  }
  if (this.msgType !== null && this.msgType !== undefined) {
    output.writeFieldBegin('msgType', Thrift.Type.I32, 2);
    output.writeI32(this.msgType);
    output.writeFieldEnd();
  }
  if (this.errorDesc !== null && this.errorDesc !== undefined) {
    output.writeFieldBegin('errorDesc', Thrift.Type.STRING, 3);
    output.writeString(this.errorDesc);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

