//
// Autogenerated by Thrift Compiler (0.9.3)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


UserRole = {
  'ADMIN' : 1,
  'ORGANIZATION' : 100,
  'CONSUMER' : 200,
  'TRIAL' : 300,
  'ANONYMOUS' : 400,
  'EXPERIED' : 1000,
  'LOCKED' : 1001,
  'INVALID' : 1002
};
DashboardType = {
  'DOCUMENT' : 1,
  'NEWS' : 2,
  'SOCIAL' : 3,
  'CUSTOM' : 100
};
WidgetType = {
  'WORD_CLOUD' : 1,
  'WORD_DETAILS' : 2,
  'MAP_TREE' : 3,
  'COMPLEXITY_VIEW' : 4,
  'DOCUMENT_LIST' : 100,
  'DOCUMENT_DETAILS' : 101,
  'DOCUMENT_GEO' : 102,
  'NEWS_LIST' : 200,
  'NEWS_DETAILS' : 201,
  'NEWS_GEO' : 202,
  'SOCIAL_LIST' : 300,
  'SOCIAL_DETAILS' : 301,
  'SOCIAL_GEO' : 302,
  'DETAILS' : 500,
  'AGGR' : 501,
  'MULTI_WIDGET' : 1000
};
SessionType = {
  'CURRENT' : 1,
  'HISTORY' : 2,
  'FAILED' : 3
};
SyncDirection = {
  'FROM_ES' : 1,
  'FROM_DB' : 2
};
CheckoutType = {
  'SINGLE_LICENCSE_1_MONTH' : 1,
  'SINGLE_LICENCSE_6_MONTH' : 2,
  'SINGLE_LICENCSE_12_MONTH' : 3
};
CheckoutStatus = {
  'PENDING' : 0,
  'COMPLETE' : 1,
  'CANCEL' : 2,
  'INVALID' : 10
};
GeneratePromotionUsersStrategy = {
  'ONE_THIRD' : 1,
  'REMAINDER' : 2
};
User = function(args) {
  this.email = null;
  this.passwd = null;
  this.role = null;
  this.firstName = null;
  this.lastName = null;
  this.organizationId = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.lockedTime = null;
  this.active = null;
  this.confirmed = null;
  this.phone = null;
  this.currentCase = null;
  this.username = null;
  this.layoutSettings = null;
  this.currentTwitterChannel = null;
  this.uiTemplateId = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.passwd !== undefined && args.passwd !== null) {
      this.passwd = args.passwd;
    }
    if (args.role !== undefined && args.role !== null) {
      this.role = args.role;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.lockedTime !== undefined && args.lockedTime !== null) {
      this.lockedTime = args.lockedTime;
    }
    if (args.active !== undefined && args.active !== null) {
      this.active = args.active;
    }
    if (args.confirmed !== undefined && args.confirmed !== null) {
      this.confirmed = args.confirmed;
    }
    if (args.phone !== undefined && args.phone !== null) {
      this.phone = args.phone;
    }
    if (args.currentCase !== undefined && args.currentCase !== null) {
      this.currentCase = args.currentCase;
    }
    if (args.username !== undefined && args.username !== null) {
      this.username = args.username;
    }
    if (args.layoutSettings !== undefined && args.layoutSettings !== null) {
      this.layoutSettings = args.layoutSettings;
    }
    if (args.currentTwitterChannel !== undefined && args.currentTwitterChannel !== null) {
      this.currentTwitterChannel = args.currentTwitterChannel;
    }
    if (args.uiTemplateId !== undefined && args.uiTemplateId !== null) {
      this.uiTemplateId = args.uiTemplateId;
    }
  }
};
User.prototype = {};
User.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.passwd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.role = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I64) {
        this.lockedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.BOOL) {
        this.active = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.BOOL) {
        this.confirmed = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.phone = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.currentCase = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.username = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.layoutSettings = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRING) {
        this.currentTwitterChannel = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.STRING) {
        this.uiTemplateId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.prototype.write = function(output) {
  output.writeStructBegin('User');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.passwd !== null && this.passwd !== undefined) {
    output.writeFieldBegin('passwd', Thrift.Type.STRING, 2);
    output.writeString(this.passwd);
    output.writeFieldEnd();
  }
  if (this.role !== null && this.role !== undefined) {
    output.writeFieldBegin('role', Thrift.Type.I32, 3);
    output.writeI32(this.role);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 4);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 5);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 6);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 7);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 8);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.lockedTime !== null && this.lockedTime !== undefined) {
    output.writeFieldBegin('lockedTime', Thrift.Type.I64, 10);
    output.writeI64(this.lockedTime);
    output.writeFieldEnd();
  }
  if (this.active !== null && this.active !== undefined) {
    output.writeFieldBegin('active', Thrift.Type.BOOL, 11);
    output.writeBool(this.active);
    output.writeFieldEnd();
  }
  if (this.confirmed !== null && this.confirmed !== undefined) {
    output.writeFieldBegin('confirmed', Thrift.Type.BOOL, 12);
    output.writeBool(this.confirmed);
    output.writeFieldEnd();
  }
  if (this.phone !== null && this.phone !== undefined) {
    output.writeFieldBegin('phone', Thrift.Type.STRING, 13);
    output.writeString(this.phone);
    output.writeFieldEnd();
  }
  if (this.currentCase !== null && this.currentCase !== undefined) {
    output.writeFieldBegin('currentCase', Thrift.Type.STRING, 14);
    output.writeString(this.currentCase);
    output.writeFieldEnd();
  }
  if (this.username !== null && this.username !== undefined) {
    output.writeFieldBegin('username', Thrift.Type.STRING, 15);
    output.writeString(this.username);
    output.writeFieldEnd();
  }
  if (this.layoutSettings !== null && this.layoutSettings !== undefined) {
    output.writeFieldBegin('layoutSettings', Thrift.Type.STRING, 16);
    output.writeString(this.layoutSettings);
    output.writeFieldEnd();
  }
  if (this.currentTwitterChannel !== null && this.currentTwitterChannel !== undefined) {
    output.writeFieldBegin('currentTwitterChannel', Thrift.Type.STRING, 17);
    output.writeString(this.currentTwitterChannel);
    output.writeFieldEnd();
  }
  if (this.uiTemplateId !== null && this.uiTemplateId !== undefined) {
    output.writeFieldBegin('uiTemplateId', Thrift.Type.STRING, 18);
    output.writeString(this.uiTemplateId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Widget = function(args) {
  this.id = null;
  this.name = null;
  this.type = null;
  this.height = null;
  this.widgets = null;
  this.freqTrendy = null;
  this.corr = null;
  this.fromYear = null;
  this.showFreq = null;
  this.showPercentage = null;
  this.showRelNorm = null;
  this.sortBy = null;
  this.locationOnly = null;
  this.spreadDensity = null;
  this.docGeoMaxLoad = null;
  this.size = null;
  this.detailsId = null;
  this.docType = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.height !== undefined && args.height !== null) {
      this.height = args.height;
    }
    if (args.widgets !== undefined && args.widgets !== null) {
      this.widgets = Thrift.copyList(args.widgets, [null]);
    }
    if (args.freqTrendy !== undefined && args.freqTrendy !== null) {
      this.freqTrendy = args.freqTrendy;
    }
    if (args.corr !== undefined && args.corr !== null) {
      this.corr = args.corr;
    }
    if (args.fromYear !== undefined && args.fromYear !== null) {
      this.fromYear = args.fromYear;
    }
    if (args.showFreq !== undefined && args.showFreq !== null) {
      this.showFreq = args.showFreq;
    }
    if (args.showPercentage !== undefined && args.showPercentage !== null) {
      this.showPercentage = args.showPercentage;
    }
    if (args.showRelNorm !== undefined && args.showRelNorm !== null) {
      this.showRelNorm = args.showRelNorm;
    }
    if (args.sortBy !== undefined && args.sortBy !== null) {
      this.sortBy = args.sortBy;
    }
    if (args.locationOnly !== undefined && args.locationOnly !== null) {
      this.locationOnly = args.locationOnly;
    }
    if (args.spreadDensity !== undefined && args.spreadDensity !== null) {
      this.spreadDensity = args.spreadDensity;
    }
    if (args.docGeoMaxLoad !== undefined && args.docGeoMaxLoad !== null) {
      this.docGeoMaxLoad = args.docGeoMaxLoad;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.detailsId !== undefined && args.detailsId !== null) {
      this.detailsId = args.detailsId;
    }
    if (args.docType !== undefined && args.docType !== null) {
      this.docType = args.docType;
    }
  }
};
Widget.prototype = {};
Widget.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.DOUBLE) {
        this.height = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.widgets = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new Widget();
          elem6.read(input);
          this.widgets.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.freqTrendy = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.corr = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.fromYear = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.BOOL) {
        this.showFreq = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.BOOL) {
        this.showPercentage = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.BOOL) {
        this.showRelNorm = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.sortBy = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.BOOL) {
        this.locationOnly = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.spreadDensity = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.I32) {
        this.docGeoMaxLoad = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.I32) {
        this.size = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRING) {
        this.detailsId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.I32) {
        this.docType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Widget.prototype.write = function(output) {
  output.writeStructBegin('Widget');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 3);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.height !== null && this.height !== undefined) {
    output.writeFieldBegin('height', Thrift.Type.DOUBLE, 4);
    output.writeDouble(this.height);
    output.writeFieldEnd();
  }
  if (this.widgets !== null && this.widgets !== undefined) {
    output.writeFieldBegin('widgets', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.widgets.length);
    for (var iter7 in this.widgets)
    {
      if (this.widgets.hasOwnProperty(iter7))
      {
        iter7 = this.widgets[iter7];
        iter7.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.freqTrendy !== null && this.freqTrendy !== undefined) {
    output.writeFieldBegin('freqTrendy', Thrift.Type.I32, 6);
    output.writeI32(this.freqTrendy);
    output.writeFieldEnd();
  }
  if (this.corr !== null && this.corr !== undefined) {
    output.writeFieldBegin('corr', Thrift.Type.I32, 7);
    output.writeI32(this.corr);
    output.writeFieldEnd();
  }
  if (this.fromYear !== null && this.fromYear !== undefined) {
    output.writeFieldBegin('fromYear', Thrift.Type.I32, 8);
    output.writeI32(this.fromYear);
    output.writeFieldEnd();
  }
  if (this.showFreq !== null && this.showFreq !== undefined) {
    output.writeFieldBegin('showFreq', Thrift.Type.BOOL, 9);
    output.writeBool(this.showFreq);
    output.writeFieldEnd();
  }
  if (this.showPercentage !== null && this.showPercentage !== undefined) {
    output.writeFieldBegin('showPercentage', Thrift.Type.BOOL, 10);
    output.writeBool(this.showPercentage);
    output.writeFieldEnd();
  }
  if (this.showRelNorm !== null && this.showRelNorm !== undefined) {
    output.writeFieldBegin('showRelNorm', Thrift.Type.BOOL, 11);
    output.writeBool(this.showRelNorm);
    output.writeFieldEnd();
  }
  if (this.sortBy !== null && this.sortBy !== undefined) {
    output.writeFieldBegin('sortBy', Thrift.Type.STRING, 12);
    output.writeString(this.sortBy);
    output.writeFieldEnd();
  }
  if (this.locationOnly !== null && this.locationOnly !== undefined) {
    output.writeFieldBegin('locationOnly', Thrift.Type.BOOL, 13);
    output.writeBool(this.locationOnly);
    output.writeFieldEnd();
  }
  if (this.spreadDensity !== null && this.spreadDensity !== undefined) {
    output.writeFieldBegin('spreadDensity', Thrift.Type.I32, 14);
    output.writeI32(this.spreadDensity);
    output.writeFieldEnd();
  }
  if (this.docGeoMaxLoad !== null && this.docGeoMaxLoad !== undefined) {
    output.writeFieldBegin('docGeoMaxLoad', Thrift.Type.I32, 15);
    output.writeI32(this.docGeoMaxLoad);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I32, 16);
    output.writeI32(this.size);
    output.writeFieldEnd();
  }
  if (this.detailsId !== null && this.detailsId !== undefined) {
    output.writeFieldBegin('detailsId', Thrift.Type.STRING, 17);
    output.writeString(this.detailsId);
    output.writeFieldEnd();
  }
  if (this.docType !== null && this.docType !== undefined) {
    output.writeFieldBegin('docType', Thrift.Type.I32, 18);
    output.writeI32(this.docType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

WidgetCol = function(args) {
  this.widgets = null;
  this.width = null;
  if (args) {
    if (args.widgets !== undefined && args.widgets !== null) {
      this.widgets = Thrift.copyList(args.widgets, [Widget]);
    }
    if (args.width !== undefined && args.width !== null) {
      this.width = args.width;
    }
  }
};
WidgetCol.prototype = {};
WidgetCol.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.widgets = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = new Widget();
          elem14.read(input);
          this.widgets.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.DOUBLE) {
        this.width = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WidgetCol.prototype.write = function(output) {
  output.writeStructBegin('WidgetCol');
  if (this.widgets !== null && this.widgets !== undefined) {
    output.writeFieldBegin('widgets', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.widgets.length);
    for (var iter15 in this.widgets)
    {
      if (this.widgets.hasOwnProperty(iter15))
      {
        iter15 = this.widgets[iter15];
        iter15.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.width !== null && this.width !== undefined) {
    output.writeFieldBegin('width', Thrift.Type.DOUBLE, 2);
    output.writeDouble(this.width);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Dashboard = function(args) {
  this.id = null;
  this.name = null;
  this.icon = null;
  this.type = null;
  this.locked = null;
  this.columns = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.icon !== undefined && args.icon !== null) {
      this.icon = args.icon;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.locked !== undefined && args.locked !== null) {
      this.locked = args.locked;
    }
    if (args.columns !== undefined && args.columns !== null) {
      this.columns = Thrift.copyList(args.columns, [WidgetCol]);
    }
  }
};
Dashboard.prototype = {};
Dashboard.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.icon = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.locked = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.columns = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new WidgetCol();
          elem22.read(input);
          this.columns.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Dashboard.prototype.write = function(output) {
  output.writeStructBegin('Dashboard');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.icon !== null && this.icon !== undefined) {
    output.writeFieldBegin('icon', Thrift.Type.STRING, 3);
    output.writeString(this.icon);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 4);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.locked !== null && this.locked !== undefined) {
    output.writeFieldBegin('locked', Thrift.Type.BOOL, 5);
    output.writeBool(this.locked);
    output.writeFieldEnd();
  }
  if (this.columns !== null && this.columns !== undefined) {
    output.writeFieldBegin('columns', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.columns.length);
    for (var iter23 in this.columns)
    {
      if (this.columns.hasOwnProperty(iter23))
      {
        iter23 = this.columns[iter23];
        iter23.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UiTemplate = function(args) {
  this.id = null;
  this.name = null;
  this.updatedTime = null;
  this.dashboards = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.dashboards !== undefined && args.dashboards !== null) {
      this.dashboards = Thrift.copyList(args.dashboards, [Dashboard]);
    }
  }
};
UiTemplate.prototype = {};
UiTemplate.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.dashboards = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new Dashboard();
          elem30.read(input);
          this.dashboards.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UiTemplate.prototype.write = function(output) {
  output.writeStructBegin('UiTemplate');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 3);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.dashboards !== null && this.dashboards !== undefined) {
    output.writeFieldBegin('dashboards', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.dashboards.length);
    for (var iter31 in this.dashboards)
    {
      if (this.dashboards.hasOwnProperty(iter31))
      {
        iter31 = this.dashboards[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateUiTemplateReq = function(args) {
  this.template = null;
  this.organizationId = null;
  if (args) {
    if (args.template !== undefined && args.template !== null) {
      this.template = new UiTemplate(args.template);
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
  }
};
UpdateUiTemplateReq.prototype = {};
UpdateUiTemplateReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.template = new UiTemplate();
        this.template.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUiTemplateReq.prototype.write = function(output) {
  output.writeStructBegin('UpdateUiTemplateReq');
  if (this.template !== null && this.template !== undefined) {
    output.writeFieldBegin('template', Thrift.Type.STRUCT, 1);
    this.template.write(output);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 2);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateUiTemplateResp = function(args) {
  this.template = null;
  this.organizationId = null;
  if (args) {
    if (args.template !== undefined && args.template !== null) {
      this.template = new UiTemplate(args.template);
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
  }
};
UpdateUiTemplateResp.prototype = {};
UpdateUiTemplateResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.template = new UiTemplate();
        this.template.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUiTemplateResp.prototype.write = function(output) {
  output.writeStructBegin('UpdateUiTemplateResp');
  if (this.template !== null && this.template !== undefined) {
    output.writeFieldBegin('template', Thrift.Type.STRUCT, 1);
    this.template.write(output);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 2);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUiTemplateReq = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
GetUiTemplateReq.prototype = {};
GetUiTemplateReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUiTemplateReq.prototype.write = function(output) {
  output.writeStructBegin('GetUiTemplateReq');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUiTemplateResp = function(args) {
  this.template = null;
  if (args) {
    if (args.template !== undefined && args.template !== null) {
      this.template = new UiTemplate(args.template);
    }
  }
};
GetUiTemplateResp.prototype = {};
GetUiTemplateResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.template = new UiTemplate();
        this.template.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUiTemplateResp.prototype.write = function(output) {
  output.writeStructBegin('GetUiTemplateResp');
  if (this.template !== null && this.template !== undefined) {
    output.writeFieldBegin('template', Thrift.Type.STRUCT, 1);
    this.template.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUiTemplatesReq = function(args) {
  this.organizationId = null;
  if (args) {
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
  }
};
GetUiTemplatesReq.prototype = {};
GetUiTemplatesReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUiTemplatesReq.prototype.write = function(output) {
  output.writeStructBegin('GetUiTemplatesReq');
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 1);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUiTemplatesResp = function(args) {
  this.templates = null;
  if (args) {
    if (args.templates !== undefined && args.templates !== null) {
      this.templates = Thrift.copyList(args.templates, [UiTemplate]);
    }
  }
};
GetUiTemplatesResp.prototype = {};
GetUiTemplatesResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.templates = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new UiTemplate();
          elem38.read(input);
          this.templates.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUiTemplatesResp.prototype.write = function(output) {
  output.writeStructBegin('GetUiTemplatesResp');
  if (this.templates !== null && this.templates !== undefined) {
    output.writeFieldBegin('templates', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.templates.length);
    for (var iter39 in this.templates)
    {
      if (this.templates.hasOwnProperty(iter39))
      {
        iter39 = this.templates[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LoginReq = function(args) {
  this.email = null;
  this.passwd = null;
  this.ipAddr = null;
  this.role = null;
  this.caseId = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.passwd !== undefined && args.passwd !== null) {
      this.passwd = args.passwd;
    }
    if (args.ipAddr !== undefined && args.ipAddr !== null) {
      this.ipAddr = args.ipAddr;
    }
    if (args.role !== undefined && args.role !== null) {
      this.role = args.role;
    }
    if (args.caseId !== undefined && args.caseId !== null) {
      this.caseId = args.caseId;
    }
  }
};
LoginReq.prototype = {};
LoginReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.passwd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.ipAddr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.role = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.caseId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LoginReq.prototype.write = function(output) {
  output.writeStructBegin('LoginReq');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.passwd !== null && this.passwd !== undefined) {
    output.writeFieldBegin('passwd', Thrift.Type.STRING, 2);
    output.writeString(this.passwd);
    output.writeFieldEnd();
  }
  if (this.ipAddr !== null && this.ipAddr !== undefined) {
    output.writeFieldBegin('ipAddr', Thrift.Type.STRING, 3);
    output.writeString(this.ipAddr);
    output.writeFieldEnd();
  }
  if (this.role !== null && this.role !== undefined) {
    output.writeFieldBegin('role', Thrift.Type.I32, 4);
    output.writeI32(this.role);
    output.writeFieldEnd();
  }
  if (this.caseId !== null && this.caseId !== undefined) {
    output.writeFieldBegin('caseId', Thrift.Type.STRING, 5);
    output.writeString(this.caseId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DeleteUiTemplateReq = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
DeleteUiTemplateReq.prototype = {};
DeleteUiTemplateReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteUiTemplateReq.prototype.write = function(output) {
  output.writeStructBegin('DeleteUiTemplateReq');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DeleteUiTemplateResp = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
DeleteUiTemplateResp.prototype = {};
DeleteUiTemplateResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteUiTemplateResp.prototype.write = function(output) {
  output.writeStructBegin('DeleteUiTemplateResp');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

LoginResp = function(args) {
  this.user = null;
  this.organization = null;
  this.cases = null;
  this.error = null;
  this.sessionTokenExpirationTime = null;
  this.twitterChannels = null;
  this.customNewsSources = null;
  this.uiTemplate = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new User(args.user);
    }
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = new Organization(args.organization);
    }
    if (args.cases !== undefined && args.cases !== null) {
      this.cases = Thrift.copyList(args.cases, [null]);
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
    if (args.sessionTokenExpirationTime !== undefined && args.sessionTokenExpirationTime !== null) {
      this.sessionTokenExpirationTime = args.sessionTokenExpirationTime;
    }
    if (args.twitterChannels !== undefined && args.twitterChannels !== null) {
      this.twitterChannels = Thrift.copyList(args.twitterChannels, [null]);
    }
    if (args.customNewsSources !== undefined && args.customNewsSources !== null) {
      this.customNewsSources = Thrift.copyList(args.customNewsSources, [null]);
    }
    if (args.uiTemplate !== undefined && args.uiTemplate !== null) {
      this.uiTemplate = new UiTemplate(args.uiTemplate);
    }
  }
};
LoginResp.prototype = {};
LoginResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.organization = new Organization();
        this.organization.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.cases = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = new Case();
          elem46.read(input);
          this.cases.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.sessionTokenExpirationTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size47 = 0;
        var _rtmp351;
        this.twitterChannels = [];
        var _etype50 = 0;
        _rtmp351 = input.readListBegin();
        _etype50 = _rtmp351.etype;
        _size47 = _rtmp351.size;
        for (var _i52 = 0; _i52 < _size47; ++_i52)
        {
          var elem53 = null;
          elem53 = new TwitterChannel();
          elem53.read(input);
          this.twitterChannels.push(elem53);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size54 = 0;
        var _rtmp358;
        this.customNewsSources = [];
        var _etype57 = 0;
        _rtmp358 = input.readListBegin();
        _etype57 = _rtmp358.etype;
        _size54 = _rtmp358.size;
        for (var _i59 = 0; _i59 < _size54; ++_i59)
        {
          var elem60 = null;
          elem60 = new Source();
          elem60.read(input);
          this.customNewsSources.push(elem60);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.uiTemplate = new UiTemplate();
        this.uiTemplate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LoginResp.prototype.write = function(output) {
  output.writeStructBegin('LoginResp');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRUCT, 2);
    this.organization.write(output);
    output.writeFieldEnd();
  }
  if (this.cases !== null && this.cases !== undefined) {
    output.writeFieldBegin('cases', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.cases.length);
    for (var iter61 in this.cases)
    {
      if (this.cases.hasOwnProperty(iter61))
      {
        iter61 = this.cases[iter61];
        iter61.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 4);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  if (this.sessionTokenExpirationTime !== null && this.sessionTokenExpirationTime !== undefined) {
    output.writeFieldBegin('sessionTokenExpirationTime', Thrift.Type.I64, 5);
    output.writeI64(this.sessionTokenExpirationTime);
    output.writeFieldEnd();
  }
  if (this.twitterChannels !== null && this.twitterChannels !== undefined) {
    output.writeFieldBegin('twitterChannels', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.twitterChannels.length);
    for (var iter62 in this.twitterChannels)
    {
      if (this.twitterChannels.hasOwnProperty(iter62))
      {
        iter62 = this.twitterChannels[iter62];
        iter62.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.customNewsSources !== null && this.customNewsSources !== undefined) {
    output.writeFieldBegin('customNewsSources', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.customNewsSources.length);
    for (var iter63 in this.customNewsSources)
    {
      if (this.customNewsSources.hasOwnProperty(iter63))
      {
        iter63 = this.customNewsSources[iter63];
        iter63.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.uiTemplate !== null && this.uiTemplate !== undefined) {
    output.writeFieldBegin('uiTemplate', Thrift.Type.STRUCT, 8);
    this.uiTemplate.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Logout = function(args) {
  this.reason = null;
  if (args) {
    if (args.reason !== undefined && args.reason !== null) {
      this.reason = args.reason;
    }
  }
};
Logout.prototype = {};
Logout.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.reason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Logout.prototype.write = function(output) {
  output.writeStructBegin('Logout');
  if (this.reason !== null && this.reason !== undefined) {
    output.writeFieldBegin('reason', Thrift.Type.STRING, 1);
    output.writeString(this.reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSessionReq = function(args) {
  this.type = null;
  this.dates = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.dates !== undefined && args.dates !== null) {
      this.dates = Thrift.copyList(args.dates, [null]);
    }
  }
};
GetSessionReq.prototype = {};
GetSessionReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size64 = 0;
        var _rtmp368;
        this.dates = [];
        var _etype67 = 0;
        _rtmp368 = input.readListBegin();
        _etype67 = _rtmp368.etype;
        _size64 = _rtmp368.size;
        for (var _i69 = 0; _i69 < _size64; ++_i69)
        {
          var elem70 = null;
          elem70 = input.readString().value;
          this.dates.push(elem70);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSessionReq.prototype.write = function(output) {
  output.writeStructBegin('GetSessionReq');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.dates !== null && this.dates !== undefined) {
    output.writeFieldBegin('dates', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRING, this.dates.length);
    for (var iter71 in this.dates)
    {
      if (this.dates.hasOwnProperty(iter71))
      {
        iter71 = this.dates[iter71];
        output.writeString(iter71);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Session = function(args) {
  this.id = null;
  this.email = null;
  this.createdDate = null;
  this.createdTime = null;
  this.closedTime = null;
  this.ipAddr = null;
  this.location = null;
  this.reason = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.createdDate !== undefined && args.createdDate !== null) {
      this.createdDate = args.createdDate;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.closedTime !== undefined && args.closedTime !== null) {
      this.closedTime = args.closedTime;
    }
    if (args.ipAddr !== undefined && args.ipAddr !== null) {
      this.ipAddr = args.ipAddr;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.reason !== undefined && args.reason !== null) {
      this.reason = args.reason;
    }
  }
};
Session.prototype = {};
Session.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.createdDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.closedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.ipAddr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.reason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Session.prototype.write = function(output) {
  output.writeStructBegin('Session');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 2);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.createdDate !== null && this.createdDate !== undefined) {
    output.writeFieldBegin('createdDate', Thrift.Type.STRING, 3);
    output.writeString(this.createdDate);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 4);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.closedTime !== null && this.closedTime !== undefined) {
    output.writeFieldBegin('closedTime', Thrift.Type.I64, 5);
    output.writeI64(this.closedTime);
    output.writeFieldEnd();
  }
  if (this.ipAddr !== null && this.ipAddr !== undefined) {
    output.writeFieldBegin('ipAddr', Thrift.Type.STRING, 6);
    output.writeString(this.ipAddr);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 7);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.reason !== null && this.reason !== undefined) {
    output.writeFieldBegin('reason', Thrift.Type.STRING, 8);
    output.writeString(this.reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSessionResp = function(args) {
  this.sessions = null;
  if (args) {
    if (args.sessions !== undefined && args.sessions !== null) {
      this.sessions = Thrift.copyList(args.sessions, [Session]);
    }
  }
};
GetSessionResp.prototype = {};
GetSessionResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size72 = 0;
        var _rtmp376;
        this.sessions = [];
        var _etype75 = 0;
        _rtmp376 = input.readListBegin();
        _etype75 = _rtmp376.etype;
        _size72 = _rtmp376.size;
        for (var _i77 = 0; _i77 < _size72; ++_i77)
        {
          var elem78 = null;
          elem78 = new Session();
          elem78.read(input);
          this.sessions.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSessionResp.prototype.write = function(output) {
  output.writeStructBegin('GetSessionResp');
  if (this.sessions !== null && this.sessions !== undefined) {
    output.writeFieldBegin('sessions', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.sessions.length);
    for (var iter79 in this.sessions)
    {
      if (this.sessions.hasOwnProperty(iter79))
      {
        iter79 = this.sessions[iter79];
        iter79.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateUser = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new User(args.user);
    }
  }
};
UpdateUser.prototype = {};
UpdateUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUser.prototype.write = function(output) {
  output.writeStructBegin('UpdateUser');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DeleteUser = function(args) {
  this.email = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
  }
};
DeleteUser.prototype = {};
DeleteUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteUser.prototype.write = function(output) {
  output.writeStructBegin('DeleteUser');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUserReq = function(args) {
};
GetUserReq.prototype = {};
GetUserReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUserReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetUserResp = function(args) {
  this.users = null;
  if (args) {
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [User]);
    }
  }
};
GetUserResp.prototype = {};
GetUserResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size80 = 0;
        var _rtmp384;
        this.users = [];
        var _etype83 = 0;
        _rtmp384 = input.readListBegin();
        _etype83 = _rtmp384.etype;
        _size80 = _rtmp384.size;
        for (var _i85 = 0; _i85 < _size80; ++_i85)
        {
          var elem86 = null;
          elem86 = new User();
          elem86.read(input);
          this.users.push(elem86);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUserResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserResp');
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter87 in this.users)
    {
      if (this.users.hasOwnProperty(iter87))
      {
        iter87 = this.users[iter87];
        iter87.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdatePasswd = function(args) {
  this.email = null;
  this.oldPasswd = null;
  this.newPasswd = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.oldPasswd !== undefined && args.oldPasswd !== null) {
      this.oldPasswd = args.oldPasswd;
    }
    if (args.newPasswd !== undefined && args.newPasswd !== null) {
      this.newPasswd = args.newPasswd;
    }
  }
};
UpdatePasswd.prototype = {};
UpdatePasswd.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.oldPasswd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.newPasswd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePasswd.prototype.write = function(output) {
  output.writeStructBegin('UpdatePasswd');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.oldPasswd !== null && this.oldPasswd !== undefined) {
    output.writeFieldBegin('oldPasswd', Thrift.Type.STRING, 2);
    output.writeString(this.oldPasswd);
    output.writeFieldEnd();
  }
  if (this.newPasswd !== null && this.newPasswd !== undefined) {
    output.writeFieldBegin('newPasswd', Thrift.Type.STRING, 3);
    output.writeString(this.newPasswd);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateCurrentCase = function(args) {
  this.email = null;
  this.currentCase = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.currentCase !== undefined && args.currentCase !== null) {
      this.currentCase = args.currentCase;
    }
  }
};
UpdateCurrentCase.prototype = {};
UpdateCurrentCase.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.currentCase = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateCurrentCase.prototype.write = function(output) {
  output.writeStructBegin('UpdateCurrentCase');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.currentCase !== null && this.currentCase !== undefined) {
    output.writeFieldBegin('currentCase', Thrift.Type.STRING, 2);
    output.writeString(this.currentCase);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Case = function(args) {
  this.id = null;
  this.name = null;
  this.keyword = null;
  this.keywords = null;
  this.createdTime = null;
  this.updatedTime = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
  }
};
Case.prototype = {};
Case.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size88 = 0;
        var _rtmp392;
        this.keywords = [];
        var _etype91 = 0;
        _rtmp392 = input.readListBegin();
        _etype91 = _rtmp392.etype;
        _size88 = _rtmp392.size;
        for (var _i93 = 0; _i93 < _size88; ++_i93)
        {
          var elem94 = null;
          elem94 = input.readString().value;
          this.keywords.push(elem94);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Case.prototype.write = function(output) {
  output.writeStructBegin('Case');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 3);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter95 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter95))
      {
        iter95 = this.keywords[iter95];
        output.writeString(iter95);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 5);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 6);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateCase = function(args) {
  this.c = null;
  if (args) {
    if (args.c !== undefined && args.c !== null) {
      this.c = new Case(args.c);
    }
  }
};
UpdateCase.prototype = {};
UpdateCase.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.c = new Case();
        this.c.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateCase.prototype.write = function(output) {
  output.writeStructBegin('UpdateCase');
  if (this.c !== null && this.c !== undefined) {
    output.writeFieldBegin('c', Thrift.Type.STRUCT, 1);
    this.c.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCaseReq = function(args) {
};
GetCaseReq.prototype = {};
GetCaseReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaseReq.prototype.write = function(output) {
  output.writeStructBegin('GetCaseReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCaseResp = function(args) {
  this.cases = null;
  if (args) {
    if (args.cases !== undefined && args.cases !== null) {
      this.cases = Thrift.copyList(args.cases, [Case]);
    }
  }
};
GetCaseResp.prototype = {};
GetCaseResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size96 = 0;
        var _rtmp3100;
        this.cases = [];
        var _etype99 = 0;
        _rtmp3100 = input.readListBegin();
        _etype99 = _rtmp3100.etype;
        _size96 = _rtmp3100.size;
        for (var _i101 = 0; _i101 < _size96; ++_i101)
        {
          var elem102 = null;
          elem102 = new Case();
          elem102.read(input);
          this.cases.push(elem102);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaseResp.prototype.write = function(output) {
  output.writeStructBegin('GetCaseResp');
  if (this.cases !== null && this.cases !== undefined) {
    output.writeFieldBegin('cases', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.cases.length);
    for (var iter103 in this.cases)
    {
      if (this.cases.hasOwnProperty(iter103))
      {
        iter103 = this.cases[iter103];
        iter103.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Organization = function(args) {
  this.id = null;
  this.name = null;
  this.keyword = null;
  this.keywords = null;
  this.channel = null;
  this.subChannel = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.cases = null;
  this.twitterChannels = null;
  this.maxNoOfTwitterChannels = null;
  this.customNewsSources = null;
  this.maxNoOfCustomNewsSources = null;
  this.uiTemplates = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.keyword !== undefined && args.keyword !== null) {
      this.keyword = args.keyword;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.channel !== undefined && args.channel !== null) {
      this.channel = args.channel;
    }
    if (args.subChannel !== undefined && args.subChannel !== null) {
      this.subChannel = args.subChannel;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.cases !== undefined && args.cases !== null) {
      this.cases = Thrift.copyList(args.cases, [null]);
    }
    if (args.twitterChannels !== undefined && args.twitterChannels !== null) {
      this.twitterChannels = Thrift.copyList(args.twitterChannels, [null]);
    }
    if (args.maxNoOfTwitterChannels !== undefined && args.maxNoOfTwitterChannels !== null) {
      this.maxNoOfTwitterChannels = args.maxNoOfTwitterChannels;
    }
    if (args.customNewsSources !== undefined && args.customNewsSources !== null) {
      this.customNewsSources = Thrift.copyList(args.customNewsSources, [null]);
    }
    if (args.maxNoOfCustomNewsSources !== undefined && args.maxNoOfCustomNewsSources !== null) {
      this.maxNoOfCustomNewsSources = args.maxNoOfCustomNewsSources;
    }
    if (args.uiTemplates !== undefined && args.uiTemplates !== null) {
      this.uiTemplates = Thrift.copyList(args.uiTemplates, [null]);
    }
  }
};
Organization.prototype = {};
Organization.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.keyword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size104 = 0;
        var _rtmp3108;
        this.keywords = [];
        var _etype107 = 0;
        _rtmp3108 = input.readListBegin();
        _etype107 = _rtmp3108.etype;
        _size104 = _rtmp3108.size;
        for (var _i109 = 0; _i109 < _size104; ++_i109)
        {
          var elem110 = null;
          elem110 = input.readString().value;
          this.keywords.push(elem110);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.channel = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.subChannel = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size111 = 0;
        var _rtmp3115;
        this.cases = [];
        var _etype114 = 0;
        _rtmp3115 = input.readListBegin();
        _etype114 = _rtmp3115.etype;
        _size111 = _rtmp3115.size;
        for (var _i116 = 0; _i116 < _size111; ++_i116)
        {
          var elem117 = null;
          elem117 = input.readString().value;
          this.cases.push(elem117);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size118 = 0;
        var _rtmp3122;
        this.twitterChannels = [];
        var _etype121 = 0;
        _rtmp3122 = input.readListBegin();
        _etype121 = _rtmp3122.etype;
        _size118 = _rtmp3122.size;
        for (var _i123 = 0; _i123 < _size118; ++_i123)
        {
          var elem124 = null;
          elem124 = input.readString().value;
          this.twitterChannels.push(elem124);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I32) {
        this.maxNoOfTwitterChannels = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.LIST) {
        var _size125 = 0;
        var _rtmp3129;
        this.customNewsSources = [];
        var _etype128 = 0;
        _rtmp3129 = input.readListBegin();
        _etype128 = _rtmp3129.etype;
        _size125 = _rtmp3129.size;
        for (var _i130 = 0; _i130 < _size125; ++_i130)
        {
          var elem131 = null;
          elem131 = input.readString().value;
          this.customNewsSources.push(elem131);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.I32) {
        this.maxNoOfCustomNewsSources = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.LIST) {
        var _size132 = 0;
        var _rtmp3136;
        this.uiTemplates = [];
        var _etype135 = 0;
        _rtmp3136 = input.readListBegin();
        _etype135 = _rtmp3136.etype;
        _size132 = _rtmp3136.size;
        for (var _i137 = 0; _i137 < _size132; ++_i137)
        {
          var elem138 = null;
          elem138 = input.readString().value;
          this.uiTemplates.push(elem138);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Organization.prototype.write = function(output) {
  output.writeStructBegin('Organization');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.keyword !== null && this.keyword !== undefined) {
    output.writeFieldBegin('keyword', Thrift.Type.STRING, 3);
    output.writeString(this.keyword);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter139 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter139))
      {
        iter139 = this.keywords[iter139];
        output.writeString(iter139);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.channel !== null && this.channel !== undefined) {
    output.writeFieldBegin('channel', Thrift.Type.STRING, 5);
    output.writeString(this.channel);
    output.writeFieldEnd();
  }
  if (this.subChannel !== null && this.subChannel !== undefined) {
    output.writeFieldBegin('subChannel', Thrift.Type.STRING, 6);
    output.writeString(this.subChannel);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 7);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 8);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.cases !== null && this.cases !== undefined) {
    output.writeFieldBegin('cases', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRING, this.cases.length);
    for (var iter140 in this.cases)
    {
      if (this.cases.hasOwnProperty(iter140))
      {
        iter140 = this.cases[iter140];
        output.writeString(iter140);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.twitterChannels !== null && this.twitterChannels !== undefined) {
    output.writeFieldBegin('twitterChannels', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.STRING, this.twitterChannels.length);
    for (var iter141 in this.twitterChannels)
    {
      if (this.twitterChannels.hasOwnProperty(iter141))
      {
        iter141 = this.twitterChannels[iter141];
        output.writeString(iter141);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.maxNoOfTwitterChannels !== null && this.maxNoOfTwitterChannels !== undefined) {
    output.writeFieldBegin('maxNoOfTwitterChannels', Thrift.Type.I32, 11);
    output.writeI32(this.maxNoOfTwitterChannels);
    output.writeFieldEnd();
  }
  if (this.customNewsSources !== null && this.customNewsSources !== undefined) {
    output.writeFieldBegin('customNewsSources', Thrift.Type.LIST, 12);
    output.writeListBegin(Thrift.Type.STRING, this.customNewsSources.length);
    for (var iter142 in this.customNewsSources)
    {
      if (this.customNewsSources.hasOwnProperty(iter142))
      {
        iter142 = this.customNewsSources[iter142];
        output.writeString(iter142);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.maxNoOfCustomNewsSources !== null && this.maxNoOfCustomNewsSources !== undefined) {
    output.writeFieldBegin('maxNoOfCustomNewsSources', Thrift.Type.I32, 13);
    output.writeI32(this.maxNoOfCustomNewsSources);
    output.writeFieldEnd();
  }
  if (this.uiTemplates !== null && this.uiTemplates !== undefined) {
    output.writeFieldBegin('uiTemplates', Thrift.Type.LIST, 14);
    output.writeListBegin(Thrift.Type.STRING, this.uiTemplates.length);
    for (var iter143 in this.uiTemplates)
    {
      if (this.uiTemplates.hasOwnProperty(iter143))
      {
        iter143 = this.uiTemplates[iter143];
        output.writeString(iter143);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateOrganization = function(args) {
  this.organization = null;
  if (args) {
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = new Organization(args.organization);
    }
  }
};
UpdateOrganization.prototype = {};
UpdateOrganization.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.organization = new Organization();
        this.organization.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateOrganization.prototype.write = function(output) {
  output.writeStructBegin('UpdateOrganization');
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRUCT, 1);
    this.organization.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

DeleteOrganization = function(args) {
  this.id = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
DeleteOrganization.prototype = {};
DeleteOrganization.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DeleteOrganization.prototype.write = function(output) {
  output.writeStructBegin('DeleteOrganization');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetOrganizationReq = function(args) {
};
GetOrganizationReq.prototype = {};
GetOrganizationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetOrganizationReq.prototype.write = function(output) {
  output.writeStructBegin('GetOrganizationReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetOrganizationResp = function(args) {
  this.organizations = null;
  if (args) {
    if (args.organizations !== undefined && args.organizations !== null) {
      this.organizations = Thrift.copyList(args.organizations, [Organization]);
    }
  }
};
GetOrganizationResp.prototype = {};
GetOrganizationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size144 = 0;
        var _rtmp3148;
        this.organizations = [];
        var _etype147 = 0;
        _rtmp3148 = input.readListBegin();
        _etype147 = _rtmp3148.etype;
        _size144 = _rtmp3148.size;
        for (var _i149 = 0; _i149 < _size144; ++_i149)
        {
          var elem150 = null;
          elem150 = new Organization();
          elem150.read(input);
          this.organizations.push(elem150);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetOrganizationResp.prototype.write = function(output) {
  output.writeStructBegin('GetOrganizationResp');
  if (this.organizations !== null && this.organizations !== undefined) {
    output.writeFieldBegin('organizations', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.organizations.length);
    for (var iter151 in this.organizations)
    {
      if (this.organizations.hasOwnProperty(iter151))
      {
        iter151 = this.organizations[iter151];
        iter151.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCaptchaReq = function(args) {
};
GetCaptchaReq.prototype = {};
GetCaptchaReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaptchaReq.prototype.write = function(output) {
  output.writeStructBegin('GetCaptchaReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCaptchaResp = function(args) {
  this.captchaId = null;
  this.captchaDate = null;
  this.captchaSvg = null;
  if (args) {
    if (args.captchaId !== undefined && args.captchaId !== null) {
      this.captchaId = args.captchaId;
    }
    if (args.captchaDate !== undefined && args.captchaDate !== null) {
      this.captchaDate = args.captchaDate;
    }
    if (args.captchaSvg !== undefined && args.captchaSvg !== null) {
      this.captchaSvg = args.captchaSvg;
    }
  }
};
GetCaptchaResp.prototype = {};
GetCaptchaResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.captchaId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.captchaDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.captchaSvg = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaptchaResp.prototype.write = function(output) {
  output.writeStructBegin('GetCaptchaResp');
  if (this.captchaId !== null && this.captchaId !== undefined) {
    output.writeFieldBegin('captchaId', Thrift.Type.STRING, 1);
    output.writeString(this.captchaId);
    output.writeFieldEnd();
  }
  if (this.captchaDate !== null && this.captchaDate !== undefined) {
    output.writeFieldBegin('captchaDate', Thrift.Type.STRING, 2);
    output.writeString(this.captchaDate);
    output.writeFieldEnd();
  }
  if (this.captchaSvg !== null && this.captchaSvg !== undefined) {
    output.writeFieldBegin('captchaSvg', Thrift.Type.STRING, 3);
    output.writeString(this.captchaSvg);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SignupReq = function(args) {
  this.email = null;
  this.firstName = null;
  this.lastName = null;
  this.organization = null;
  this.captchaText = null;
  this.captchaId = null;
  this.captchaDate = null;
  this.order = null;
  this.caseName = null;
  this.keywords = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.organization !== undefined && args.organization !== null) {
      this.organization = args.organization;
    }
    if (args.captchaText !== undefined && args.captchaText !== null) {
      this.captchaText = args.captchaText;
    }
    if (args.captchaId !== undefined && args.captchaId !== null) {
      this.captchaId = args.captchaId;
    }
    if (args.captchaDate !== undefined && args.captchaDate !== null) {
      this.captchaDate = args.captchaDate;
    }
    if (args.order !== undefined && args.order !== null) {
      this.order = args.order;
    }
    if (args.caseName !== undefined && args.caseName !== null) {
      this.caseName = args.caseName;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
  }
};
SignupReq.prototype = {};
SignupReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.organization = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.captchaText = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.captchaId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.captchaDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.order = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.caseName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size152 = 0;
        var _rtmp3156;
        this.keywords = [];
        var _etype155 = 0;
        _rtmp3156 = input.readListBegin();
        _etype155 = _rtmp3156.etype;
        _size152 = _rtmp3156.size;
        for (var _i157 = 0; _i157 < _size152; ++_i157)
        {
          var elem158 = null;
          elem158 = input.readString().value;
          this.keywords.push(elem158);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignupReq.prototype.write = function(output) {
  output.writeStructBegin('SignupReq');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 2);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 3);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.organization !== null && this.organization !== undefined) {
    output.writeFieldBegin('organization', Thrift.Type.STRING, 4);
    output.writeString(this.organization);
    output.writeFieldEnd();
  }
  if (this.captchaText !== null && this.captchaText !== undefined) {
    output.writeFieldBegin('captchaText', Thrift.Type.STRING, 5);
    output.writeString(this.captchaText);
    output.writeFieldEnd();
  }
  if (this.captchaId !== null && this.captchaId !== undefined) {
    output.writeFieldBegin('captchaId', Thrift.Type.STRING, 6);
    output.writeString(this.captchaId);
    output.writeFieldEnd();
  }
  if (this.captchaDate !== null && this.captchaDate !== undefined) {
    output.writeFieldBegin('captchaDate', Thrift.Type.STRING, 7);
    output.writeString(this.captchaDate);
    output.writeFieldEnd();
  }
  if (this.order !== null && this.order !== undefined) {
    output.writeFieldBegin('order', Thrift.Type.STRING, 8);
    output.writeString(this.order);
    output.writeFieldEnd();
  }
  if (this.caseName !== null && this.caseName !== undefined) {
    output.writeFieldBegin('caseName', Thrift.Type.STRING, 9);
    output.writeString(this.caseName);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter159 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter159))
      {
        iter159 = this.keywords[iter159];
        output.writeString(iter159);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SignupResp = function(args) {
  this.error = null;
  if (args) {
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
  }
};
SignupResp.prototype = {};
SignupResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignupResp.prototype.write = function(output) {
  output.writeStructBegin('SignupResp');
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 1);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Location = function(args) {
  this.lat = null;
  this.lon = null;
  if (args) {
    if (args.lat !== undefined && args.lat !== null) {
      this.lat = args.lat;
    }
    if (args.lon !== undefined && args.lon !== null) {
      this.lon = args.lon;
    }
  }
};
Location.prototype = {};
Location.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.DOUBLE) {
        this.lat = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.DOUBLE) {
        this.lon = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Location.prototype.write = function(output) {
  output.writeStructBegin('Location');
  if (this.lat !== null && this.lat !== undefined) {
    output.writeFieldBegin('lat', Thrift.Type.DOUBLE, 1);
    output.writeDouble(this.lat);
    output.writeFieldEnd();
  }
  if (this.lon !== null && this.lon !== undefined) {
    output.writeFieldBegin('lon', Thrift.Type.DOUBLE, 2);
    output.writeDouble(this.lon);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Address = function(args) {
  this.country = null;
  this.state = null;
  this.city = null;
  this.street = null;
  if (args) {
    if (args.country !== undefined && args.country !== null) {
      this.country = args.country;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.city !== undefined && args.city !== null) {
      this.city = args.city;
    }
    if (args.street !== undefined && args.street !== null) {
      this.street = args.street;
    }
  }
};
Address.prototype = {};
Address.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.country = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.state = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.city = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.street = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Address.prototype.write = function(output) {
  output.writeStructBegin('Address');
  if (this.country !== null && this.country !== undefined) {
    output.writeFieldBegin('country', Thrift.Type.STRING, 1);
    output.writeString(this.country);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.STRING, 2);
    output.writeString(this.state);
    output.writeFieldEnd();
  }
  if (this.city !== null && this.city !== undefined) {
    output.writeFieldBegin('city', Thrift.Type.STRING, 3);
    output.writeString(this.city);
    output.writeFieldEnd();
  }
  if (this.street !== null && this.street !== undefined) {
    output.writeFieldBegin('street', Thrift.Type.STRING, 4);
    output.writeString(this.street);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Source = function(args) {
  this.id = null;
  this.type = null;
  this.accessType = null;
  this.name = null;
  this.desc = null;
  this.url = null;
  this.website = null;
  this.lang = null;
  this.keywords = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.location = null;
  this.address = null;
  this.trusted = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.accessType !== undefined && args.accessType !== null) {
      this.accessType = args.accessType;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.desc !== undefined && args.desc !== null) {
      this.desc = args.desc;
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
    if (args.website !== undefined && args.website !== null) {
      this.website = args.website;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = new Location(args.location);
    }
    if (args.address !== undefined && args.address !== null) {
      this.address = new Address(args.address);
    }
    if (args.trusted !== undefined && args.trusted !== null) {
      this.trusted = args.trusted;
    }
  }
};
Source.prototype = {};
Source.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.accessType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.desc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.website = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size160 = 0;
        var _rtmp3164;
        this.keywords = [];
        var _etype163 = 0;
        _rtmp3164 = input.readListBegin();
        _etype163 = _rtmp3164.etype;
        _size160 = _rtmp3164.size;
        for (var _i165 = 0; _i165 < _size160; ++_i165)
        {
          var elem166 = null;
          elem166 = input.readString().value;
          this.keywords.push(elem166);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.location = new Location();
        this.location.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRUCT) {
        this.address = new Address();
        this.address.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.trusted = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Source.prototype.write = function(output) {
  output.writeStructBegin('Source');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 2);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.accessType !== null && this.accessType !== undefined) {
    output.writeFieldBegin('accessType', Thrift.Type.STRING, 3);
    output.writeString(this.accessType);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 4);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.desc !== null && this.desc !== undefined) {
    output.writeFieldBegin('desc', Thrift.Type.STRING, 5);
    output.writeString(this.desc);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 6);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  if (this.website !== null && this.website !== undefined) {
    output.writeFieldBegin('website', Thrift.Type.STRING, 7);
    output.writeString(this.website);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 8);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter167 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter167))
      {
        iter167 = this.keywords[iter167];
        output.writeString(iter167);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 10);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 11);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRUCT, 12);
    this.location.write(output);
    output.writeFieldEnd();
  }
  if (this.address !== null && this.address !== undefined) {
    output.writeFieldBegin('address', Thrift.Type.STRUCT, 13);
    this.address.write(output);
    output.writeFieldEnd();
  }
  if (this.trusted !== null && this.trusted !== undefined) {
    output.writeFieldBegin('trusted', Thrift.Type.I32, 14);
    output.writeI32(this.trusted);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateSource = function(args) {
  this.type = null;
  this.source = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = new Source(args.source);
    }
  }
};
UpdateSource.prototype = {};
UpdateSource.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.source = new Source();
        this.source.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateSource.prototype.write = function(output) {
  output.writeStructBegin('UpdateSource');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRUCT, 2);
    this.source.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSourceReq = function(args) {
  this.type = null;
  this.start = null;
  this.size = null;
  this.query = null;
  this.ids = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.start !== undefined && args.start !== null) {
      this.start = args.start;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.query !== undefined && args.query !== null) {
      this.query = args.query;
    }
    if (args.ids !== undefined && args.ids !== null) {
      this.ids = Thrift.copyList(args.ids, [null]);
    }
  }
};
GetSourceReq.prototype = {};
GetSourceReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.start = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.size = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.query = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size168 = 0;
        var _rtmp3172;
        this.ids = [];
        var _etype171 = 0;
        _rtmp3172 = input.readListBegin();
        _etype171 = _rtmp3172.etype;
        _size168 = _rtmp3172.size;
        for (var _i173 = 0; _i173 < _size168; ++_i173)
        {
          var elem174 = null;
          elem174 = input.readString().value;
          this.ids.push(elem174);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSourceReq.prototype.write = function(output) {
  output.writeStructBegin('GetSourceReq');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.start !== null && this.start !== undefined) {
    output.writeFieldBegin('start', Thrift.Type.I32, 2);
    output.writeI32(this.start);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I32, 3);
    output.writeI32(this.size);
    output.writeFieldEnd();
  }
  if (this.query !== null && this.query !== undefined) {
    output.writeFieldBegin('query', Thrift.Type.STRING, 4);
    output.writeString(this.query);
    output.writeFieldEnd();
  }
  if (this.ids !== null && this.ids !== undefined) {
    output.writeFieldBegin('ids', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRING, this.ids.length);
    for (var iter175 in this.ids)
    {
      if (this.ids.hasOwnProperty(iter175))
      {
        iter175 = this.ids[iter175];
        output.writeString(iter175);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSourceResp = function(args) {
  this.sources = null;
  this.total = null;
  if (args) {
    if (args.sources !== undefined && args.sources !== null) {
      this.sources = Thrift.copyList(args.sources, [Source]);
    }
    if (args.total !== undefined && args.total !== null) {
      this.total = args.total;
    }
  }
};
GetSourceResp.prototype = {};
GetSourceResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size176 = 0;
        var _rtmp3180;
        this.sources = [];
        var _etype179 = 0;
        _rtmp3180 = input.readListBegin();
        _etype179 = _rtmp3180.etype;
        _size176 = _rtmp3180.size;
        for (var _i181 = 0; _i181 < _size176; ++_i181)
        {
          var elem182 = null;
          elem182 = new Source();
          elem182.read(input);
          this.sources.push(elem182);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.total = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSourceResp.prototype.write = function(output) {
  output.writeStructBegin('GetSourceResp');
  if (this.sources !== null && this.sources !== undefined) {
    output.writeFieldBegin('sources', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.sources.length);
    for (var iter183 in this.sources)
    {
      if (this.sources.hasOwnProperty(iter183))
      {
        iter183 = this.sources[iter183];
        iter183.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.total !== null && this.total !== undefined) {
    output.writeFieldBegin('total', Thrift.Type.I32, 2);
    output.writeI32(this.total);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSourceStatReq = function(args) {
  this.type = null;
  this.ids = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.ids !== undefined && args.ids !== null) {
      this.ids = Thrift.copyList(args.ids, [null]);
    }
  }
};
GetSourceStatReq.prototype = {};
GetSourceStatReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size184 = 0;
        var _rtmp3188;
        this.ids = [];
        var _etype187 = 0;
        _rtmp3188 = input.readListBegin();
        _etype187 = _rtmp3188.etype;
        _size184 = _rtmp3188.size;
        for (var _i189 = 0; _i189 < _size184; ++_i189)
        {
          var elem190 = null;
          elem190 = input.readString().value;
          this.ids.push(elem190);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSourceStatReq.prototype.write = function(output) {
  output.writeStructBegin('GetSourceStatReq');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.ids !== null && this.ids !== undefined) {
    output.writeFieldBegin('ids', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.ids.length);
    for (var iter191 in this.ids)
    {
      if (this.ids.hasOwnProperty(iter191))
      {
        iter191 = this.ids[iter191];
        output.writeString(iter191);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SourceDayStat = function(args) {
  this.day = null;
  this.docs = null;
  if (args) {
    if (args.day !== undefined && args.day !== null) {
      this.day = args.day;
    }
    if (args.docs !== undefined && args.docs !== null) {
      this.docs = args.docs;
    }
  }
};
SourceDayStat.prototype = {};
SourceDayStat.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.day = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.docs = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SourceDayStat.prototype.write = function(output) {
  output.writeStructBegin('SourceDayStat');
  if (this.day !== null && this.day !== undefined) {
    output.writeFieldBegin('day', Thrift.Type.STRING, 1);
    output.writeString(this.day);
    output.writeFieldEnd();
  }
  if (this.docs !== null && this.docs !== undefined) {
    output.writeFieldBegin('docs', Thrift.Type.I32, 2);
    output.writeI32(this.docs);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SourceStat = function(args) {
  this.id = null;
  this.days = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.days !== undefined && args.days !== null) {
      this.days = Thrift.copyList(args.days, [SourceDayStat]);
    }
  }
};
SourceStat.prototype = {};
SourceStat.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size192 = 0;
        var _rtmp3196;
        this.days = [];
        var _etype195 = 0;
        _rtmp3196 = input.readListBegin();
        _etype195 = _rtmp3196.etype;
        _size192 = _rtmp3196.size;
        for (var _i197 = 0; _i197 < _size192; ++_i197)
        {
          var elem198 = null;
          elem198 = new SourceDayStat();
          elem198.read(input);
          this.days.push(elem198);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SourceStat.prototype.write = function(output) {
  output.writeStructBegin('SourceStat');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.days !== null && this.days !== undefined) {
    output.writeFieldBegin('days', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.days.length);
    for (var iter199 in this.days)
    {
      if (this.days.hasOwnProperty(iter199))
      {
        iter199 = this.days[iter199];
        iter199.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetSourceStatResp = function(args) {
  this.stats = null;
  if (args) {
    if (args.stats !== undefined && args.stats !== null) {
      this.stats = Thrift.copyList(args.stats, [SourceStat]);
    }
  }
};
GetSourceStatResp.prototype = {};
GetSourceStatResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size200 = 0;
        var _rtmp3204;
        this.stats = [];
        var _etype203 = 0;
        _rtmp3204 = input.readListBegin();
        _etype203 = _rtmp3204.etype;
        _size200 = _rtmp3204.size;
        for (var _i205 = 0; _i205 < _size200; ++_i205)
        {
          var elem206 = null;
          elem206 = new SourceStat();
          elem206.read(input);
          this.stats.push(elem206);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSourceStatResp.prototype.write = function(output) {
  output.writeStructBegin('GetSourceStatResp');
  if (this.stats !== null && this.stats !== undefined) {
    output.writeFieldBegin('stats', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.stats.length);
    for (var iter207 in this.stats)
    {
      if (this.stats.hasOwnProperty(iter207))
      {
        iter207 = this.stats[iter207];
        iter207.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SyncSources = function(args) {
  this.direction = null;
  if (args) {
    if (args.direction !== undefined && args.direction !== null) {
      this.direction = args.direction;
    }
  }
};
SyncSources.prototype = {};
SyncSources.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.direction = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SyncSources.prototype.write = function(output) {
  output.writeStructBegin('SyncSources');
  if (this.direction !== null && this.direction !== undefined) {
    output.writeFieldBegin('direction', Thrift.Type.I32, 1);
    output.writeI32(this.direction);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ScopeRange = function(args) {
  this.type = null;
  this.value = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
  }
};
ScopeRange.prototype = {};
ScopeRange.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ScopeRange.prototype.write = function(output) {
  output.writeStructBegin('ScopeRange');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 2);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Scope = function(args) {
  this.id = null;
  this.organizationId = null;
  this.name = null;
  this.desc = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.types = null;
  this.sourceIds = null;
  this.fromRange = null;
  this.toRange = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.desc !== undefined && args.desc !== null) {
      this.desc = args.desc;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.types !== undefined && args.types !== null) {
      this.types = Thrift.copyList(args.types, [null]);
    }
    if (args.sourceIds !== undefined && args.sourceIds !== null) {
      this.sourceIds = Thrift.copyList(args.sourceIds, [null]);
    }
    if (args.fromRange !== undefined && args.fromRange !== null) {
      this.fromRange = new ScopeRange(args.fromRange);
    }
    if (args.toRange !== undefined && args.toRange !== null) {
      this.toRange = new ScopeRange(args.toRange);
    }
  }
};
Scope.prototype = {};
Scope.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.desc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size208 = 0;
        var _rtmp3212;
        this.types = [];
        var _etype211 = 0;
        _rtmp3212 = input.readListBegin();
        _etype211 = _rtmp3212.etype;
        _size208 = _rtmp3212.size;
        for (var _i213 = 0; _i213 < _size208; ++_i213)
        {
          var elem214 = null;
          elem214 = input.readString().value;
          this.types.push(elem214);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.LIST) {
        var _size215 = 0;
        var _rtmp3219;
        this.sourceIds = [];
        var _etype218 = 0;
        _rtmp3219 = input.readListBegin();
        _etype218 = _rtmp3219.etype;
        _size215 = _rtmp3219.size;
        for (var _i220 = 0; _i220 < _size215; ++_i220)
        {
          var elem221 = null;
          elem221 = input.readString().value;
          this.sourceIds.push(elem221);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.fromRange = new ScopeRange();
        this.fromRange.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.toRange = new ScopeRange();
        this.toRange.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Scope.prototype.write = function(output) {
  output.writeStructBegin('Scope');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 2);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 3);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.desc !== null && this.desc !== undefined) {
    output.writeFieldBegin('desc', Thrift.Type.STRING, 4);
    output.writeString(this.desc);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 5);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 6);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.types !== null && this.types !== undefined) {
    output.writeFieldBegin('types', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRING, this.types.length);
    for (var iter222 in this.types)
    {
      if (this.types.hasOwnProperty(iter222))
      {
        iter222 = this.types[iter222];
        output.writeString(iter222);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sourceIds !== null && this.sourceIds !== undefined) {
    output.writeFieldBegin('sourceIds', Thrift.Type.LIST, 8);
    output.writeListBegin(Thrift.Type.STRING, this.sourceIds.length);
    for (var iter223 in this.sourceIds)
    {
      if (this.sourceIds.hasOwnProperty(iter223))
      {
        iter223 = this.sourceIds[iter223];
        output.writeString(iter223);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.fromRange !== null && this.fromRange !== undefined) {
    output.writeFieldBegin('fromRange', Thrift.Type.STRUCT, 9);
    this.fromRange.write(output);
    output.writeFieldEnd();
  }
  if (this.toRange !== null && this.toRange !== undefined) {
    output.writeFieldBegin('toRange', Thrift.Type.STRUCT, 10);
    this.toRange.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateScope = function(args) {
  this.scope = null;
  if (args) {
    if (args.scope !== undefined && args.scope !== null) {
      this.scope = new Scope(args.scope);
    }
  }
};
UpdateScope.prototype = {};
UpdateScope.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.scope = new Scope();
        this.scope.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateScope.prototype.write = function(output) {
  output.writeStructBegin('UpdateScope');
  if (this.scope !== null && this.scope !== undefined) {
    output.writeFieldBegin('scope', Thrift.Type.STRUCT, 1);
    this.scope.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetScopeReq = function(args) {
  this.organizationId = null;
  if (args) {
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
  }
};
GetScopeReq.prototype = {};
GetScopeReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetScopeReq.prototype.write = function(output) {
  output.writeStructBegin('GetScopeReq');
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 1);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetScopeResp = function(args) {
  this.scopes = null;
  if (args) {
    if (args.scopes !== undefined && args.scopes !== null) {
      this.scopes = Thrift.copyList(args.scopes, [Scope]);
    }
  }
};
GetScopeResp.prototype = {};
GetScopeResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size224 = 0;
        var _rtmp3228;
        this.scopes = [];
        var _etype227 = 0;
        _rtmp3228 = input.readListBegin();
        _etype227 = _rtmp3228.etype;
        _size224 = _rtmp3228.size;
        for (var _i229 = 0; _i229 < _size224; ++_i229)
        {
          var elem230 = null;
          elem230 = new Scope();
          elem230.read(input);
          this.scopes.push(elem230);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetScopeResp.prototype.write = function(output) {
  output.writeStructBegin('GetScopeResp');
  if (this.scopes !== null && this.scopes !== undefined) {
    output.writeFieldBegin('scopes', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.scopes.length);
    for (var iter231 in this.scopes)
    {
      if (this.scopes.hasOwnProperty(iter231))
      {
        iter231 = this.scopes[iter231];
        iter231.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SendEmailReq = function(args) {
  this.sendTo = null;
  this.sendFrom = null;
  this.replyTo = null;
  this.subject = null;
  this.htmlBody = null;
  if (args) {
    if (args.sendTo !== undefined && args.sendTo !== null) {
      this.sendTo = args.sendTo;
    }
    if (args.sendFrom !== undefined && args.sendFrom !== null) {
      this.sendFrom = args.sendFrom;
    }
    if (args.replyTo !== undefined && args.replyTo !== null) {
      this.replyTo = args.replyTo;
    }
    if (args.subject !== undefined && args.subject !== null) {
      this.subject = args.subject;
    }
    if (args.htmlBody !== undefined && args.htmlBody !== null) {
      this.htmlBody = args.htmlBody;
    }
  }
};
SendEmailReq.prototype = {};
SendEmailReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.sendTo = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.sendFrom = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.replyTo = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.subject = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.htmlBody = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendEmailReq.prototype.write = function(output) {
  output.writeStructBegin('SendEmailReq');
  if (this.sendTo !== null && this.sendTo !== undefined) {
    output.writeFieldBegin('sendTo', Thrift.Type.STRING, 1);
    output.writeString(this.sendTo);
    output.writeFieldEnd();
  }
  if (this.sendFrom !== null && this.sendFrom !== undefined) {
    output.writeFieldBegin('sendFrom', Thrift.Type.STRING, 2);
    output.writeString(this.sendFrom);
    output.writeFieldEnd();
  }
  if (this.replyTo !== null && this.replyTo !== undefined) {
    output.writeFieldBegin('replyTo', Thrift.Type.STRING, 3);
    output.writeString(this.replyTo);
    output.writeFieldEnd();
  }
  if (this.subject !== null && this.subject !== undefined) {
    output.writeFieldBegin('subject', Thrift.Type.STRING, 4);
    output.writeString(this.subject);
    output.writeFieldEnd();
  }
  if (this.htmlBody !== null && this.htmlBody !== undefined) {
    output.writeFieldBegin('htmlBody', Thrift.Type.STRING, 5);
    output.writeString(this.htmlBody);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SendEmailResp = function(args) {
  this.info = null;
  this.error = null;
  if (args) {
    if (args.info !== undefined && args.info !== null) {
      this.info = args.info;
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
  }
};
SendEmailResp.prototype = {};
SendEmailResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.info = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendEmailResp.prototype.write = function(output) {
  output.writeStructBegin('SendEmailResp');
  if (this.info !== null && this.info !== undefined) {
    output.writeFieldBegin('info', Thrift.Type.STRING, 1);
    output.writeString(this.info);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 2);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

TwitterChannel = function(args) {
  this.id = null;
  this.name = null;
  this.tags = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.active = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.tags !== undefined && args.tags !== null) {
      this.tags = Thrift.copyList(args.tags, [null]);
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.active !== undefined && args.active !== null) {
      this.active = args.active;
    }
  }
};
TwitterChannel.prototype = {};
TwitterChannel.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size232 = 0;
        var _rtmp3236;
        this.tags = [];
        var _etype235 = 0;
        _rtmp3236 = input.readListBegin();
        _etype235 = _rtmp3236.etype;
        _size232 = _rtmp3236.size;
        for (var _i237 = 0; _i237 < _size232; ++_i237)
        {
          var elem238 = null;
          elem238 = input.readString().value;
          this.tags.push(elem238);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.active = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TwitterChannel.prototype.write = function(output) {
  output.writeStructBegin('TwitterChannel');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.tags !== null && this.tags !== undefined) {
    output.writeFieldBegin('tags', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRING, this.tags.length);
    for (var iter239 in this.tags)
    {
      if (this.tags.hasOwnProperty(iter239))
      {
        iter239 = this.tags[iter239];
        output.writeString(iter239);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 4);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 5);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.active !== null && this.active !== undefined) {
    output.writeFieldBegin('active', Thrift.Type.BOOL, 6);
    output.writeBool(this.active);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateTwitterChannel = function(args) {
  this.channel = null;
  if (args) {
    if (args.channel !== undefined && args.channel !== null) {
      this.channel = new TwitterChannel(args.channel);
    }
  }
};
UpdateTwitterChannel.prototype = {};
UpdateTwitterChannel.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.channel = new TwitterChannel();
        this.channel.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateTwitterChannel.prototype.write = function(output) {
  output.writeStructBegin('UpdateTwitterChannel');
  if (this.channel !== null && this.channel !== undefined) {
    output.writeFieldBegin('channel', Thrift.Type.STRUCT, 1);
    this.channel.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateTwitterChannelReq = function(args) {
  this.channel = null;
  if (args) {
    if (args.channel !== undefined && args.channel !== null) {
      this.channel = new TwitterChannel(args.channel);
    }
  }
};
UpdateTwitterChannelReq.prototype = {};
UpdateTwitterChannelReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.channel = new TwitterChannel();
        this.channel.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateTwitterChannelReq.prototype.write = function(output) {
  output.writeStructBegin('UpdateTwitterChannelReq');
  if (this.channel !== null && this.channel !== undefined) {
    output.writeFieldBegin('channel', Thrift.Type.STRUCT, 1);
    this.channel.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateTwitterChannelResp = function(args) {
  this.channel = null;
  if (args) {
    if (args.channel !== undefined && args.channel !== null) {
      this.channel = new TwitterChannel(args.channel);
    }
  }
};
UpdateTwitterChannelResp.prototype = {};
UpdateTwitterChannelResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.channel = new TwitterChannel();
        this.channel.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateTwitterChannelResp.prototype.write = function(output) {
  output.writeStructBegin('UpdateTwitterChannelResp');
  if (this.channel !== null && this.channel !== undefined) {
    output.writeFieldBegin('channel', Thrift.Type.STRUCT, 1);
    this.channel.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetTwitterChannelReq = function(args) {
};
GetTwitterChannelReq.prototype = {};
GetTwitterChannelReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetTwitterChannelReq.prototype.write = function(output) {
  output.writeStructBegin('GetTwitterChannelReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetTwitterChannelResp = function(args) {
  this.channels = null;
  if (args) {
    if (args.channels !== undefined && args.channels !== null) {
      this.channels = Thrift.copyList(args.channels, [TwitterChannel]);
    }
  }
};
GetTwitterChannelResp.prototype = {};
GetTwitterChannelResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size240 = 0;
        var _rtmp3244;
        this.channels = [];
        var _etype243 = 0;
        _rtmp3244 = input.readListBegin();
        _etype243 = _rtmp3244.etype;
        _size240 = _rtmp3244.size;
        for (var _i245 = 0; _i245 < _size240; ++_i245)
        {
          var elem246 = null;
          elem246 = new TwitterChannel();
          elem246.read(input);
          this.channels.push(elem246);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetTwitterChannelResp.prototype.write = function(output) {
  output.writeStructBegin('GetTwitterChannelResp');
  if (this.channels !== null && this.channels !== undefined) {
    output.writeFieldBegin('channels', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.channels.length);
    for (var iter247 in this.channels)
    {
      if (this.channels.hasOwnProperty(iter247))
      {
        iter247 = this.channels[iter247];
        iter247.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

UpdateCurrentTwitterChannel = function(args) {
  this.email = null;
  this.currentTwitterChannel = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.currentTwitterChannel !== undefined && args.currentTwitterChannel !== null) {
      this.currentTwitterChannel = args.currentTwitterChannel;
    }
  }
};
UpdateCurrentTwitterChannel.prototype = {};
UpdateCurrentTwitterChannel.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.currentTwitterChannel = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateCurrentTwitterChannel.prototype.write = function(output) {
  output.writeStructBegin('UpdateCurrentTwitterChannel');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.currentTwitterChannel !== null && this.currentTwitterChannel !== undefined) {
    output.writeFieldBegin('currentTwitterChannel', Thrift.Type.STRING, 2);
    output.writeString(this.currentTwitterChannel);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CheckNewsFeedReq = function(args) {
  this.url = null;
  if (args) {
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
  }
};
CheckNewsFeedReq.prototype = {};
CheckNewsFeedReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CheckNewsFeedReq.prototype.write = function(output) {
  output.writeStructBegin('CheckNewsFeedReq');
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 1);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

NewsFeed = function(args) {
  this.title = null;
  this.content = null;
  this.link = null;
  if (args) {
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.content !== undefined && args.content !== null) {
      this.content = args.content;
    }
    if (args.link !== undefined && args.link !== null) {
      this.link = args.link;
    }
  }
};
NewsFeed.prototype = {};
NewsFeed.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.content = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.link = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

NewsFeed.prototype.write = function(output) {
  output.writeStructBegin('NewsFeed');
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 1);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRING, 2);
    output.writeString(this.content);
    output.writeFieldEnd();
  }
  if (this.link !== null && this.link !== undefined) {
    output.writeFieldBegin('link', Thrift.Type.STRING, 3);
    output.writeString(this.link);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

CheckNewsFeedResp = function(args) {
  this.feeds = null;
  if (args) {
    if (args.feeds !== undefined && args.feeds !== null) {
      this.feeds = Thrift.copyList(args.feeds, [NewsFeed]);
    }
  }
};
CheckNewsFeedResp.prototype = {};
CheckNewsFeedResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size248 = 0;
        var _rtmp3252;
        this.feeds = [];
        var _etype251 = 0;
        _rtmp3252 = input.readListBegin();
        _etype251 = _rtmp3252.etype;
        _size248 = _rtmp3252.size;
        for (var _i253 = 0; _i253 < _size248; ++_i253)
        {
          var elem254 = null;
          elem254 = new NewsFeed();
          elem254.read(input);
          this.feeds.push(elem254);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CheckNewsFeedResp.prototype.write = function(output) {
  output.writeStructBegin('CheckNewsFeedResp');
  if (this.feeds !== null && this.feeds !== undefined) {
    output.writeFieldBegin('feeds', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.feeds.length);
    for (var iter255 in this.feeds)
    {
      if (this.feeds.hasOwnProperty(iter255))
      {
        iter255 = this.feeds[iter255];
        iter255.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

License = function(args) {
  this.id = null;
  this.organizationId = null;
  this.createdTime = null;
  this.startedTime = null;
  this.expiredTime = null;
  this.type = null;
  this.cases = null;
  this.caseChangesLeft = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.startedTime !== undefined && args.startedTime !== null) {
      this.startedTime = args.startedTime;
    }
    if (args.expiredTime !== undefined && args.expiredTime !== null) {
      this.expiredTime = args.expiredTime;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.cases !== undefined && args.cases !== null) {
      this.cases = Thrift.copyList(args.cases, [null]);
    }
    if (args.caseChangesLeft !== undefined && args.caseChangesLeft !== null) {
      this.caseChangesLeft = args.caseChangesLeft;
    }
  }
};
License.prototype = {};
License.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.startedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.expiredTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size256 = 0;
        var _rtmp3260;
        this.cases = [];
        var _etype259 = 0;
        _rtmp3260 = input.readListBegin();
        _etype259 = _rtmp3260.etype;
        _size256 = _rtmp3260.size;
        for (var _i261 = 0; _i261 < _size256; ++_i261)
        {
          var elem262 = null;
          elem262 = input.readString().value;
          this.cases.push(elem262);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.caseChangesLeft = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

License.prototype.write = function(output) {
  output.writeStructBegin('License');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 2);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 3);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.startedTime !== null && this.startedTime !== undefined) {
    output.writeFieldBegin('startedTime', Thrift.Type.I64, 4);
    output.writeI64(this.startedTime);
    output.writeFieldEnd();
  }
  if (this.expiredTime !== null && this.expiredTime !== undefined) {
    output.writeFieldBegin('expiredTime', Thrift.Type.I64, 5);
    output.writeI64(this.expiredTime);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 6);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.cases !== null && this.cases !== undefined) {
    output.writeFieldBegin('cases', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRING, this.cases.length);
    for (var iter263 in this.cases)
    {
      if (this.cases.hasOwnProperty(iter263))
      {
        iter263 = this.cases[iter263];
        output.writeString(iter263);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.caseChangesLeft !== null && this.caseChangesLeft !== undefined) {
    output.writeFieldBegin('caseChangesLeft', Thrift.Type.I32, 8);
    output.writeI32(this.caseChangesLeft);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCheckoutSessionReq = function(args) {
  this.email = null;
  this.type = null;
  this.images = null;
  this.successUrl = null;
  this.cancelUrl = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.images !== undefined && args.images !== null) {
      this.images = args.images;
    }
    if (args.successUrl !== undefined && args.successUrl !== null) {
      this.successUrl = args.successUrl;
    }
    if (args.cancelUrl !== undefined && args.cancelUrl !== null) {
      this.cancelUrl = args.cancelUrl;
    }
  }
};
GetCheckoutSessionReq.prototype = {};
GetCheckoutSessionReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.images = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.successUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.cancelUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCheckoutSessionReq.prototype.write = function(output) {
  output.writeStructBegin('GetCheckoutSessionReq');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.images !== null && this.images !== undefined) {
    output.writeFieldBegin('images', Thrift.Type.STRING, 3);
    output.writeString(this.images);
    output.writeFieldEnd();
  }
  if (this.successUrl !== null && this.successUrl !== undefined) {
    output.writeFieldBegin('successUrl', Thrift.Type.STRING, 4);
    output.writeString(this.successUrl);
    output.writeFieldEnd();
  }
  if (this.cancelUrl !== null && this.cancelUrl !== undefined) {
    output.writeFieldBegin('cancelUrl', Thrift.Type.STRING, 5);
    output.writeString(this.cancelUrl);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GetCheckoutSessionResp = function(args) {
  this.session = null;
  if (args) {
    if (args.session !== undefined && args.session !== null) {
      this.session = args.session;
    }
  }
};
GetCheckoutSessionResp.prototype = {};
GetCheckoutSessionResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.session = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCheckoutSessionResp.prototype.write = function(output) {
  output.writeStructBegin('GetCheckoutSessionResp');
  if (this.session !== null && this.session !== undefined) {
    output.writeFieldBegin('session', Thrift.Type.STRING, 1);
    output.writeString(this.session);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SetCheckoutStatusReq = function(args) {
  this.session = null;
  this.status = null;
  if (args) {
    if (args.session !== undefined && args.session !== null) {
      this.session = args.session;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
  }
};
SetCheckoutStatusReq.prototype = {};
SetCheckoutStatusReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.session = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SetCheckoutStatusReq.prototype.write = function(output) {
  output.writeStructBegin('SetCheckoutStatusReq');
  if (this.session !== null && this.session !== undefined) {
    output.writeFieldBegin('session', Thrift.Type.STRING, 1);
    output.writeString(this.session);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 2);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

SetCheckoutStatusResp = function(args) {
  this.session = null;
  this.status = null;
  this.organizationId = null;
  this.license = null;
  if (args) {
    if (args.session !== undefined && args.session !== null) {
      this.session = args.session;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.organizationId !== undefined && args.organizationId !== null) {
      this.organizationId = args.organizationId;
    }
    if (args.license !== undefined && args.license !== null) {
      this.license = new License(args.license);
    }
  }
};
SetCheckoutStatusResp.prototype = {};
SetCheckoutStatusResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.session = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.organizationId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.license = new License();
        this.license.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SetCheckoutStatusResp.prototype.write = function(output) {
  output.writeStructBegin('SetCheckoutStatusResp');
  if (this.session !== null && this.session !== undefined) {
    output.writeFieldBegin('session', Thrift.Type.STRING, 1);
    output.writeString(this.session);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 2);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.organizationId !== null && this.organizationId !== undefined) {
    output.writeFieldBegin('organizationId', Thrift.Type.STRING, 3);
    output.writeString(this.organizationId);
    output.writeFieldEnd();
  }
  if (this.license !== null && this.license !== undefined) {
    output.writeFieldBegin('license', Thrift.Type.STRUCT, 4);
    this.license.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

StartPromotion = function(args) {
  this.promotionId = null;
  this.sendFrom = null;
  this.replyTo = null;
  this.subject = null;
  if (args) {
    if (args.promotionId !== undefined && args.promotionId !== null) {
      this.promotionId = args.promotionId;
    }
    if (args.sendFrom !== undefined && args.sendFrom !== null) {
      this.sendFrom = args.sendFrom;
    }
    if (args.replyTo !== undefined && args.replyTo !== null) {
      this.replyTo = args.replyTo;
    }
    if (args.subject !== undefined && args.subject !== null) {
      this.subject = args.subject;
    }
  }
};
StartPromotion.prototype = {};
StartPromotion.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.promotionId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.sendFrom = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.replyTo = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.subject = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

StartPromotion.prototype.write = function(output) {
  output.writeStructBegin('StartPromotion');
  if (this.promotionId !== null && this.promotionId !== undefined) {
    output.writeFieldBegin('promotionId', Thrift.Type.STRING, 1);
    output.writeString(this.promotionId);
    output.writeFieldEnd();
  }
  if (this.sendFrom !== null && this.sendFrom !== undefined) {
    output.writeFieldBegin('sendFrom', Thrift.Type.STRING, 2);
    output.writeString(this.sendFrom);
    output.writeFieldEnd();
  }
  if (this.replyTo !== null && this.replyTo !== undefined) {
    output.writeFieldBegin('replyTo', Thrift.Type.STRING, 3);
    output.writeString(this.replyTo);
    output.writeFieldEnd();
  }
  if (this.subject !== null && this.subject !== undefined) {
    output.writeFieldBegin('subject', Thrift.Type.STRING, 4);
    output.writeString(this.subject);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

GeneratePromotionUsers = function(args) {
  this.promotionId = null;
  this.strategy = null;
  if (args) {
    if (args.promotionId !== undefined && args.promotionId !== null) {
      this.promotionId = args.promotionId;
    }
    if (args.strategy !== undefined && args.strategy !== null) {
      this.strategy = args.strategy;
    }
  }
};
GeneratePromotionUsers.prototype = {};
GeneratePromotionUsers.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.promotionId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.strategy = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GeneratePromotionUsers.prototype.write = function(output) {
  output.writeStructBegin('GeneratePromotionUsers');
  if (this.promotionId !== null && this.promotionId !== undefined) {
    output.writeFieldBegin('promotionId', Thrift.Type.STRING, 1);
    output.writeString(this.promotionId);
    output.writeFieldEnd();
  }
  if (this.strategy !== null && this.strategy !== undefined) {
    output.writeFieldBegin('strategy', Thrift.Type.I32, 2);
    output.writeI32(this.strategy);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

